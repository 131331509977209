import React, { useState, useEffect } from 'react';
import { Modal, Spin, List, Button, Table } from 'antd';
import { getOppsMatchingConditions } from '../../../../services/admin-page-service';
import { CustomerRule } from '../../../../dto/admin-rule-models';
import { GetAccessToken } from '../../../../utils/auth-utils';
import { useMsal } from '@azure/msal-react';
import { scopes } from '../../../../authConfig';
import { CaseInfo } from '../../../../dto/model';

type CustomerRulePreviewChangesProps = {
  visible: boolean;
  onClose: () => void;
  customerRule: CustomerRule;
};

const salesForceRootUrl = (process as any).env.REACT_APP_SALESFORCE_BASE_URL;

const CustomerRulePreviewChanges: React.FC<CustomerRulePreviewChangesProps> = ({ visible, onClose, customerRule }) => {
  const [loading, setLoading] = useState(false);
  const [caseInfoList, setCaseInfoList] = useState<CaseInfo[]>([]);
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if (visible) {
      fetchCaseInfoList();
    }
  }, [visible]);

  const fetchCaseInfoList = async () => {
    setLoading(true);
    try {
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);

      const response = await getOppsMatchingConditions(token.accessToken, customerRule);
      setCaseInfoList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Opportunity ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => <span>{text}</span>
    },
    {
      title: 'Salesforce URL',
      dataIndex: 'id',
      key: 'salesforceUrl',
      render: (opportunityId: string) => (
        <a href={`${salesForceRootUrl}/lightning/r/Opportunity/${opportunityId}/view`} target="_blank" rel="noopener noreferrer">
          Salesforce Link
        </a>
      ),
    },
    {
      title: 'Calendar URL',
      dataIndex: 'id',
      key: 'calendarUrl',
      render: (opportunityId: string) => (
        <a href={`${window.location.origin}?opportunityId=${opportunityId}`} target="_blank" rel="noopener noreferrer">
          Calendar Link
        </a>
      ),
    },
    {
      title: 'IsDeleted',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      render: (text: boolean) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Sales Channel',
      dataIndex: 'salesChannel',
      key: 'salesChannel',
      render: (text: boolean) => <span>{text}</span>,
    },
  ];

  return (
    <Modal
      title="Opportunities that would be affected by the customer Rule"
      visible={visible}
      onCancel={onClose}
      width={900}
      footer={[
        <i key="note" style={{ textAlign: 'left', display: 'block', marginBottom: '10px', }}>
          Preview changes does not preview Opportunity Sync Changes
        </i>,
        <i key="note" style={{ textAlign: 'left', display: 'block', marginBottom: '10px' }}>
          Sales call 2 is not affected by opportunity sync.
        </i>,
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      {loading ? (
        <Spin tip="Loading...">
          <div style={{ height: '200px' }} />
        </Spin>
      ) : (
        <Table
          dataSource={caseInfoList}
          columns={columns}
          rowKey="opportunityId"
          pagination={{ pageSize: 7 }}
        />
      )}
    </Modal>
  );
};

export default CustomerRulePreviewChanges;