import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import { ActionDto } from '../../../../dto/reporting-models';
import Title from 'antd/es/typography/Title';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { GetAccessToken } from '../../../../utils/auth-utils';
import { scopes } from '../../../../authConfig';
import { reportingService } from '../../../../services/reporting-service';

const { Text } = Typography;
export const AdminExperience = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [actions, setActions] = useState<ActionDto[] | undefined>();

  useEffect(() => {
    const fetchActions = async () => {
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      try {
        const actionsResponse = await reportingService.getActionsAsync(token.accessToken);
        setActions(actionsResponse);
      } catch (error) {}
    };
    fetchActions();
  }, []);

  return (
    <div className="container" style={{ width: 600 }}>
      <Row justify="space-between" align="bottom" style={{ marginBottom: '20px' }}>
        <Title level={4} style={{ marginBottom: 0 }}>
          Experience Points Configuration
        </Title>
      </Row>
      <Row justify="space-between">
        <Text type="secondary" style={{ display: 'block', marginTop: '5px', marginBottom: '10px' }}>
          Note: this configuration section is used to set the amount of experience points gained for each user action.
        </Text>
      </Row>
      <Form>
        <Row justify="space-between">
          <Col span={8}>
            <h3>Actions</h3>
          </Col>
          <Col title="Experience points gain" span={8}>
            <h3>Experience points gain</h3>
          </Col>
        </Row>
        {actions &&
          actions.map((point) => (
            <Row justify="space-between">
              <Col title="Action" span={8}>
                <Form.Item key={point.actionId} label={point.actionType}></Form.Item>
              </Col>
              <Col title="Experience points gain" span={8}>
                <Form.Item name={`${point.actionId}`}>
                  <Input type="number" defaultValue={point.points} onChange={(e) => (point.points = +e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
          ))}
        <Form.Item>
          <Button
            style={{ float: 'right' }}
            type="primary"
            disabled={!actions}
            onClick={async () => {
              reportingService
                .modifyActionsAsync((await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi)).accessToken, actions!)
                .then(() => {
                  message.success('Successfully updated!');
                })
                .catch(() => {
                  message.error('Failed to update!');
                });
            }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
