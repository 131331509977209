import { Button, Col, Form, Input, Row, Select, Space, Tooltip } from "antd"
import { CustomerRule, CustomerRuleCondition, opportunityValues, RuleCondition, RuleFor } from "../../../../dto/admin-rule-models"

const { Option } = Select;

type CustomerRuleConditionsProps = {
  rule: CustomerRule
  setRule: (rule: CustomerRule) => void
}

export const CustomerRuleConditions = ({ rule, setRule }: CustomerRuleConditionsProps) => {

  const addCondition = () => {
    const newConditions = [...rule.conditions, {
      feId: crypto.randomUUID(),
      condition: RuleCondition.None,
      value: '',
      propertyName: '',
      methodNames: rule.conditions[0]?.methodNames,
    }];
    const updatedRule = {
      ...rule,
      conditions: newConditions
    };

    setRule(updatedRule);
  }

  const updateCondition = (condition: CustomerRuleCondition) => {
    const updatedConditions = rule.conditions.map(c => c.feId == condition.feId ? condition : c);
    const updatedRule = {
      ...rule,
      conditions: updatedConditions
    }
    setRule(updatedRule);
  }

  const removeCondition = (feId: string) => {
    const updatedConditions = rule.conditions.filter(condition => condition.feId != feId);
    const updatedRule = {
      ...rule,
      conditions: updatedConditions
    }
    setRule(updatedRule);
  }

  return (
    <div style={{ marginBottom: 50 }}>
      <h4>Rule Conditions</h4>
      {rule.conditions
        ?.map((condition, index) => (
          <Space key={condition.feId} align="baseline" size="small"  >
            <Form.Item style={{ width: 50 }}>
              <h4>{index == 0 ? 'IF' : 'AND'}</h4>
            </Form.Item>

            <Form.Item
              name={['opportunitySelectCondition', condition.feId]}
              rules={[{ required: true, message: 'Please select a condition!' }]}
              initialValue={condition.propertyName}
            >
              <Select
                style={{ width: 200 }}
                onChange={(value) => { condition.propertyName = value; updateCondition(condition) }}
                value={condition.propertyName}
                placeholder="Opportunity Value">
                {Object.keys(opportunityValues)
                  .map((key: string) => (
                    <Option key={key} value={key}>
                      <Tooltip title={opportunityValues[key]}>
                        <span
                          style={{ display: 'inline-block', width: '100%' }}>
                          {opportunityValues[key]}
                        </span>
                      </Tooltip>
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name={['conditionActionSelect', condition.feId]}
              rules={[{ required: true, message: 'Please select a condition action!' }]}
              initialValue={condition.condition == RuleCondition.None ? null : condition.condition}
            >
              <Select
                onChange={(value) => { condition.condition = value; updateCondition(condition) }}
                value={condition.condition == RuleCondition.None ? null : condition.condition}
                style={{ width: 120 }}>
                {Object.entries(RuleCondition)
                  .filter(([key, value]) => key != RuleCondition.None)
                  .map(([key, value]) => (
                    <Option key={key} value={value}>
                      {key}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name={['conditionValue', condition.feId]}
              rules={[{ required: true, message: 'Please select a condition value!' }]}
              initialValue={condition.value}
            >
              <Input
                style={{ width: 335 }}
                value={condition.value}
                placeholder="Value"
                onChange={(e) => {
                  condition.value = e.target.value;
                  updateCondition(condition);
                }} />
            </Form.Item>
            <Button onClick={() => removeCondition(condition.feId)}>Delete</Button>
          </Space>
        ))
      }
      <Button type="dashed" block className="my-4" onClick={() => addCondition()}>
        + Add Condition
      </Button>

    </div>
  )

}