import { Select, Row, Col, Card, Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { LineChart } from '@mantine/charts';
import { Metrics, RatingDto, RatingsResponse, TeamMemberModel, TeamModel } from '../../dto/reporting-models';
import { GetAccessToken } from '../../utils/auth-utils';
import { scopes } from '../../authConfig';
import { reportingService } from '../../services/reporting-service';
import { useMsal } from '@azure/msal-react';
import TeamRankingCard from './team-ranking-card';
import { SyncOutlined } from '@ant-design/icons';
import { strings } from '../../lang';

export const TeamReportingPage = () => {
  const [selectedTeam, setSelectedTeam] = useState<TeamModel | null>(null);
  const { instance, inProgress, accounts } = useMsal();
  const [isSpinning, setIsSpinning] = useState(false);
  const [teamOptions, setTeamOptions] = useState<TeamModel[]>([]);
  const [agents, setAgents] = useState<TeamMemberModel[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<TeamMemberModel | null>(null);
  const [ratings, setRatings] = useState<RatingsResponse>();
  const [metrics, setMetrics] = useState<Metrics>();

  useEffect(() => {
    fetchLeaderBoardData();
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      fetchAgents(selectedTeam?.id);
      fetchTeamInfo(selectedTeam?.id);
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (selectedAgent) {
      fetchSelectedUserInfo(selectedAgent.id).then((metrics) => setMetrics(metrics));
    } else {
      if (selectedTeam) {
        GetAccessToken(instance, inProgress, scopes.salesAppointmentApi).then((token) => {
          reportingService.getCurrentTeamMetricsAsync(token.accessToken).then((metrics) => setMetrics(metrics));
        });
      }
    }
  }, [selectedAgent]);

  const fetchSelectedUserInfo = async (agentId: string) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    return await reportingService.getUserMetricsAsync(token.accessToken, agentId);
  };

  const fetchLeaderBoardData = async () => {
    setIsSpinning(true);
    await fetchTeamOptions();
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    if (!selectedTeam) {
      const ratings = await reportingService.getRatingAsync(token.accessToken, true);
      setRatings(ratings);
    } else {
      const ratings = await reportingService.getTeamRatingAsync(token.accessToken, selectedTeam.id);
      setRatings(ratings);
    }

    if (!selectedAgent) {
      const metrics = await reportingService.getCurrentTeamMetricsAsync(token.accessToken);
      setMetrics(metrics);
    } else {
      const metrics = await reportingService.getUserMetricsAsync(token.accessToken, selectedAgent.id);
      setMetrics(metrics);
    }
    setIsSpinning(false);
  };

  const fetchTeamOptions = async () => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const teams = await reportingService.getTeamsAsync(token.accessToken);
    setTeamOptions(teams);
  };

  const fetchTeamInfo = async (teamId: number) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const teamRating = await reportingService.getTeamRatingAsync(token.accessToken, teamId);
    setRatings(teamRating);
  };

  const fetchAgents = async (teamId: number) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const agents = await reportingService.getAgentsByTeamIdAsync(teamId, token.accessToken);
    setAgents(agents);
  };

  const convertDataToPercentageRating = (ratingDto: RatingDto | null) => {
    return ratingDto
      ? {
          currentUserPosition: ratingDto?.currentUserPosition,
          currentUserCriteriaCount: !Number.isInteger(ratingDto?.currentUserCriteriaCount)
            ? parseFloat((ratingDto?.currentUserCriteriaCount! * 100).toFixed(1))
            : ratingDto?.currentUserCriteriaCount! * 100,
          leaderboard: ratingDto?.leaderboard
            .sort((a, b) => b.count - a.count)
            .map((booker) => ({
              ...booker,
              count: Number.isInteger(booker.count) ? booker.count * 100 : parseFloat((booker.count * 100).toFixed(1)),
            })),
        }
      : null;
  };

  const convertDataToPrecisionRating = (ratingDto: RatingDto | null) => {
    return ratingDto
      ? {
          currentUserPosition: ratingDto?.currentUserPosition,
          currentUserCriteriaCount: !Number.isInteger(ratingDto?.currentUserCriteriaCount) ? parseFloat(ratingDto?.currentUserCriteriaCount!.toFixed(1)) : ratingDto?.currentUserCriteriaCount!,
          leaderboard: ratingDto?.leaderboard
            .sort((a, b) => b.count - a.count)
            .map((booker) => ({
              ...booker,
              count: Number.isInteger(booker.count) ? booker.count : parseFloat(booker.count.toFixed(1)),
            })),
        }
      : null;
  };

  return (
    <>
      <div className="activity-page">
        <Row className="my-4" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Select
            style={{ width: '250px' }}
            placeholder={strings.reporting.selectTeamBtn}
            value={selectedTeam?.name}
            onChange={(value) => setSelectedTeam(teamOptions.find((team) => team.name === value) || null)}
            options={teamOptions.map((team) => ({ label: team.name, value: team.name }))}
          />
          <Button onClick={async () => await fetchLeaderBoardData()} type="link">
            <SyncOutlined style={{ fontSize: '20px', color: 'grey' }} spin={isSpinning} />
          </Button>
        </Row>

        <Row className="my-4" justify="start" style={{ gap: '16px', flexWrap: 'wrap' }}>
          <Tooltip title={strings.reporting.clarification.assignments}>
            <div style={{ flex: '1 1 calc(20% - 16px)', minWidth: '200px' }}>
              <TeamRankingCard title="Assignments" rankData={ratings?.assignments ?? null} total={ratings?.assignments?.leaderboard.reduce((sum, item) => sum + item.count, 0).toString() ?? '0'} />
            </div>
          </Tooltip>

          <Tooltip title={strings.reporting.clarification.assignmentsPerHour}>
            <div style={{ flex: '1 1 calc(20% - 16px)', minWidth: '200px' }}>
              <TeamRankingCard
                title={strings.reporting.ratingAssignmentPerHour}
                rankData={convertDataToPrecisionRating(ratings?.assignmentsPerHour ?? null)}
                total={
                  (ratings?.assignmentsPerHour?.leaderboard.reduce((sum, item) => sum + item.count, 0)! / ratings?.assignmentsPerHour?.leaderboard.filter((a) => a.count > 0).length!).toFixed(1) ?? '0'
                }
              />
            </div>
          </Tooltip>
          <Tooltip title={strings.reporting.clarification.bookings}>
            <div style={{ flex: '1 1 calc(20% - 16px)', minWidth: '200px' }}>
              <TeamRankingCard
                title={strings.reporting.ratingBookings}
                rankData={ratings?.topBookers ?? null}
                total={ratings?.topBookers?.leaderboard.reduce((sum, item) => sum + item.count, 0) ? ratings?.topBookers?.leaderboard.reduce((sum, item) => sum + item.count, 0).toString() : '0'}
              />
            </div>
          </Tooltip>

          <Tooltip title={strings.reporting.clarification.bookingsPerHour}>
            <div style={{ flex: '1 1 calc(20% - 16px)', minWidth: '200px' }}>
              <TeamRankingCard
                title={strings.reporting.ratingBookingsPerHour}
                rankData={convertDataToPrecisionRating(ratings?.bookingsPerHour ?? null)}
                total={(ratings?.bookingsPerHour?.leaderboard.reduce((sum, item) => sum + item.count, 0)! / ratings?.bookingsPerHour?.leaderboard.filter((a) => a.count > 0).length!).toFixed(1) ?? '0'}
              />
            </div>
          </Tooltip>

          <Tooltip title={strings.reporting.clarification.nearestAppointmentsPerToday}>
            <div style={{ flex: '1 1 calc(20% - 16px)', minWidth: '200px' }}>
              <TeamRankingCard
                title={strings.reporting.ratingSameNextDayBookings}
                rankData={convertDataToPercentageRating(ratings?.nearestAppointmentsPerToday ?? null)}
                total={
                  (
                    (ratings?.nearestAppointmentsPerToday?.leaderboard.reduce((sum, item) => sum + item.count, 0)! * 100) /
                    ratings?.nearestAppointmentsPerToday?.leaderboard.filter((a) => a.count > 0).length!
                  ).toFixed(1) ?? '0'
                }
              />
            </div>
          </Tooltip>
        </Row>

        <Row className="my-4">
          <Col span={24}>
            <Card title={strings.reporting.lblPerformanceTrends} bordered={false}>
              <Select
                style={{ width: '250px', marginBottom: '16px' }}
                placeholder={strings.reporting.selectAgentBtn}
                value={selectedAgent?.id}
                allowClear={true}
                onChange={(value) => setSelectedAgent(agents.find((agent) => agent.id === value) || null)}
                options={agents.map((agent) => ({ label: agent.fullName, value: agent.id }))}
              />
              <LineChart
                h={300}
                data={
                  metrics?.hourlyData?.todayHourlyData
                    ? metrics?.hourlyData.todayHourlyData?.map((el) => ({
                        hour: +el.hour + 1,
                        amount: el.amount,
                      }))
                    : []
                }
                dataKey="hour"
                series={[{ name: 'amount', color: 'blue.6', label: strings.reportingCallPerHour }]}
                curveType="linear"
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
