import React, { useEffect, useRef, useState } from 'react';
import {
  CommonEditComponentProps,
  ScriptsWithObjections,
} from '../../dto/model';
import LoadingBar from 'react-top-loading-bar';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import {
  Button,
  Input,
  InputNumber,
  Tabs,
  TabsProps,
} from 'antd';
import './script-editor.css';
import { ScriptEditorTab } from './script-editor-tab';

export const ScriptEditor: React.ComponentType<CommonEditComponentProps<ScriptsWithObjections>> = ({ model, onSubmit, onCancel }) => {
  const loadingBarRef = useRef(null);
  const [activeTabKey, setActiveTabKey] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scriptWithObjections, setScriptWithObjections] = useState<ScriptsWithObjections>(model);

  useEffect(() => {
    setScriptWithObjections(model);
  }, [model]);

  useEffect(() => {
    if (loadingBarRef.current) {
      if (isLoading) {
        (loadingBarRef.current! as any).staticStart();
      } else {
        (loadingBarRef.current! as any).complete();
      }
    }
  }, [isLoading]);

  let items: TabsProps['items'] | undefined = undefined;

  if (scriptWithObjections) {
    const tabs = scriptWithObjections.scripts.map((s, i) => ({
      key: s.bookingStatus,
      label: s.bookingStatus,
      children: (
        <ScriptEditorTab
          content={s.scriptText}
          onChange={(value: string) => {
            s.scriptText = value;
            setScriptWithObjections(scriptWithObjections);
          }}
        />
      ),
    }));

    const activeObjections = scriptWithObjections.objections.filter((o) => !o.isDeleted);

    tabs.push({
      key: 'objections',
      label: 'Objections',
      children: (
        <div>
          {activeObjections.map((o, i) => {
            return (
              <div className="objection">
                <div>
                  <div className="objection__header">
                    <Input
                      className="objection__text"
                      onChange={(e) => {
                        activeObjections[i].objection = e.target.value;
                        setScriptWithObjections(structuredClone(scriptWithObjections));
                      }}
                      value={o.objection}
                    />
                    <InputNumber
                      className="objection__sort"
                      onChange={(value) => {
                        activeObjections[i].sortOrder = value!;
                        setScriptWithObjections(structuredClone(scriptWithObjections));
                      }}
                      value={o.sortOrder}
                    />
                    <Button
                      danger
                      type="primary"
                      onClick={(_) => {
                        const objectionToDelete = scriptWithObjections.objections.filter(
                          (o) => !o.isDeleted
                        )[i];
                        if (objectionToDelete.id === 0) {
                          scriptWithObjections.objections.splice(
                            scriptWithObjections.objections.indexOf(objectionToDelete),
                            1
                          );
                        } else {
                          objectionToDelete.isDeleted = true;
                        }
                        if (o.id === 0) {
                        }
                        const structuredObjections = structuredClone(scriptWithObjections.objections);
                        setScriptWithObjections({ ...scriptWithObjections, ...structuredObjections });
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                  <ReactQuill
                    theme="snow"
                    value={o.answer}
                    onChange={(value: string) => {
                      o.answer = value;
                      const updatedObjections = scriptWithObjections.objections.map((objection, index) => {
                        if (index === i) {
                          return {
                            ...objection,
                            answer: value,
                          };
                        }
                        return objection;
                      });

                      const updatedScriptWithObjections = {
                        ...scriptWithObjections,
                        objections: updatedObjections,
                      };

                      setScriptWithObjections(updatedScriptWithObjections);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ),
    });

    tabs.push({
      key: 'hints',
      label: 'Hints',
      children: (
        <div>
          <h3>Available placeholders</h3>
          <ul>
            <li>[SALUTATION]</li>
            <li>[CUSTOMER_FIRST_NAME]</li>
            <li>[CUSTOMER_LAST_NAME]</li>
            <li>[FULL_ADDRESS]</li>
            <li>[CITY]</li>
            <li>[USERNAME] (the full name of the booking agent)</li>
            <li>[STRASSE]</li>
            <li>[HAUSNUMMER]</li>
            <li>[STADT]</li>
            <li>[CUSTOMER_EMAIL]</li>
          </ul>
        </div>
      ),
    });

    items = tabs;
  }

  return (
    <>
      <LoadingBar color="#3e7eff" ref={loadingBarRef} shadow={true} />
      <div >
        <div className="sales-channel">
          <label className="sales-channel__label">Script Name</label>
          <Input
            value={scriptWithObjections.name}
            onChange={(e) => {
              const updatedName = e.target.value;

              const updatedScripts = scriptWithObjections.scripts.map(script => ({
                ...script,
                name: updatedName,
              }));

              const updatedObjections = scriptWithObjections.objections.map(objection => ({
                ...objection,
                scriptName: updatedName,
              }));

              const updatedScriptWithObjections = {
                ...scriptWithObjections,
                name: updatedName,
                scripts: updatedScripts,
                objections: updatedObjections,
              };

              setScriptWithObjections(updatedScriptWithObjections);
            }}
            placeholder='Script Name'
          />
        </div>

        {/*
        Force re-render of the tab control when changing the Sales Channel
        The method loadScripts sets scripts ad objections to undefined to force the refresh,
        because the ReactQuill component is buggy and does not update the state properly.
      */}
        {scriptWithObjections.scripts && scriptWithObjections.objections && items && (
          <>
            <Tabs
              items={items}
              onChange={(activeTabKey) => setActiveTabKey(activeTabKey)}
            />
            <div className="objections__btns">
              {activeTabKey === 'objections' && (
                <Button
                  type="primary"
                  onClick={(_) => {
                    scriptWithObjections.objections.push({
                      id: -1,
                      answer: '',
                      sortOrder: scriptWithObjections.objections.length + 1,
                      objection: '',
                      isDeleted: false,
                      lastModifiedBy: '',
                      lastModifiedDate: new Date(),
                      scriptName: scriptWithObjections.name,
                    });
                    setScriptWithObjections(structuredClone(scriptWithObjections));
                  }}
                >
                  Add New
                </Button>
              )}
              <Button onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={async () => await onSubmit(scriptWithObjections)}>
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
