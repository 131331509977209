import { Card, Row, Col, Statistic, Typography, Tooltip, Button, Select } from 'antd';
import './reporting-page.css';
import { Header } from '../header/header';
import { LineChart } from '@mantine/charts';
import RankingCard from './ranking-card';
import { reportingService } from '../../services/reporting-service';
import { useEffect, useState } from 'react';
import { CaretDownOutlined, CaretUpOutlined, ExclamationOutlined, InfoCircleOutlined, MinusOutlined, SyncOutlined } from '@ant-design/icons';
import { Metrics, RatingDto, RatingsResponse } from '../../dto/reporting-models';
import dayjs from 'dayjs';
import { useMsal } from '@azure/msal-react';
import { GetAccessToken } from '../../utils/auth-utils';
import { scopes } from '../../authConfig';
import { strings } from '../../lang';

const { Title } = Typography;

interface PagePros {
  hideHeader?: boolean | undefined;
}

const ReportingPage = ({ hideHeader: showHeader }: PagePros) => {
  const { instance, inProgress, accounts } = useMsal();
  const [isSpinning, setIsSpinning] = useState(false);
  const [userMetrics, setUserMetrics] = useState<Metrics | null>();
  const [ratingData, setRatingData] = useState<RatingsResponse | null>();
  const [showRate, setShowRate] = useState<number | null>(null);
  const [bookings, setBookings] = useState<number | null>(null);
  const [reachedRate, setReachedRate] = useState<number | null>(null);
  const [bookingsRate, setBookingsRate] = useState<number | null>(null);
  const [sameNextDayBooking, setSameNextDayBooking] = useState<number | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (userMetrics) {
      const showRateMetric = userMetrics.metricData.todayMetrics.filter((metric) => metric.type === 'ShowRate')[0];
      const bookingsMetric = userMetrics.metricData.todayMetrics.filter((metric) => metric.type === 'Bookings')[0];
      const reachedRateMetric = userMetrics.metricData.todayMetrics.filter((metric) => metric.type === 'ReachedRate')[0];
      const bookingsRateMetric = userMetrics.metricData.todayMetrics.filter((metric) => metric.type === 'BookingRate')[0];
      const today = userMetrics?.metricData?.todayMetrics.filter((metric) => metric.type === 'NearestAppointmentsPerToday')[0];

      setSameNextDayBooking(+today.value);
      setShowRate(+showRateMetric.value);
      setBookings(+bookingsMetric.value);
      setReachedRate(+reachedRateMetric.value);
      setBookingsRate(+bookingsRateMetric.value);
    }
  }, [userMetrics]);

  const fetchData = async () => {
    // get access token
    setIsSpinning(true);
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);

    const metrics = await reportingService.getSelfMetricsAsync(token.accessToken);
    setUserMetrics(metrics);
    const rating = await reportingService.getRatingAsync(token.accessToken, false);
    setRatingData(rating);

    setIsSpinning(false);
  };

  const fetchRatingData = async (selection: string) => {
    setIsSpinning(true);

    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const rating = await reportingService.getRatingAsync(token.accessToken, selection === 'team');
    console.log(rating);
    setRatingData(rating);

    setIsSpinning(false);
  };

  const userActionCount = userMetrics && reachedRate && reachedRate !== 0 ? (reachedRate * 100).toFixed(1) : 0;

  const calculateBookingsDiff = () => {
    const bookingsYesterday = userMetrics?.metricData.pastMetrics.filter((metric) => metric.type === 'Bookings')[0].value;
    return bookings! - +bookingsYesterday!;
  };

  const calculateReachedRate = () => {
    const reachedRateYesterday = userMetrics?.metricData.pastMetrics.filter((metric) => metric.type === 'ReachedRate')[0].value;
    if (reachedRate === 0) {
      return -+reachedRateYesterday!;
    }
    return reachedRate! - +reachedRateYesterday!;
  };

  const calculateNearestAppPercentage = () => {
    const yesterday = userMetrics?.metricData?.pastMetrics.filter((metric) => metric.type === 'NearestAppointmentsPerToday')[0].value;
    if (sameNextDayBooking === 0) {
      return -yesterday!;
    }
    return sameNextDayBooking! - +yesterday!;
  };

  const calculateBookingsRateDiff = () => {
    const pastBookingRate = userMetrics?.metricData.pastMetrics.filter((metric) => metric.type === 'BookingRate')[0].value;
    return calculateBookingRateToday() - +pastBookingRate!;
  };

  const calculateBookingRateToday = () => {
    if (!bookingsRate) {
      return 0;
    } else {
      return bookingsRate;
    }
  };

  const getNextUpdateTimeForShowRate = () => {
    const nextTimeUtc = userMetrics?.metricData.todayMetrics.filter((metric) => metric.type === 'NextAppointmentUpdateTime')[0].value;
    return nextTimeUtc !== '' ? `${strings.reportingNextUpdateAt} ${dayjs.utc(nextTimeUtc).local().format('HH:mm')}` : strings.reportingNoAppointmentsToday;
  };

  const getShowRateClarification = () => {
    const consideredAppointments = userMetrics?.metricData.todayMetrics.filter((metric) => metric.type === 'ConsideredAppointmentAmount')[0].value;
    const appointmentsToGo = userMetrics?.metricData.todayMetrics.filter((metric) => metric.type === 'AppointmentsToGoAmount')[0].value;

    return `${strings.reportingAppointmentsConsidered}: ${consideredAppointments}
    ${strings.reportingAppointmentsToGo}: ${appointmentsToGo}`;
  };

  const calculateShowRateForUser = () => {
    if (!showRate || showRate === 0) {
      return '-';
    } else {
      return Number.isInteger(showRate) ? showRate * 100 : (showRate! * 100).toFixed(1);
    }
  };

  const convertDataToPercentageRating = (ratingDto: RatingDto | null) => {
    return ratingDto
      ? {
          currentUserPosition: ratingDto?.currentUserPosition,
          currentUserCriteriaCount: !Number.isInteger(ratingDto?.currentUserCriteriaCount)
            ? parseFloat((ratingDto?.currentUserCriteriaCount! * 100).toFixed(1))
            : ratingDto?.currentUserCriteriaCount! * 100,
          leaderboard: ratingDto?.leaderboard
            .sort((a, b) => b.count - a.count)
            .map((booker) => ({
              ...booker,
              count: Number.isInteger(booker.count) ? booker.count * 100 : parseFloat((booker.count * 100).toFixed(1)),
            })),
        }
      : null;
  };

  const calculateNettoTerminen = () => {
    const today = userMetrics?.metricData.todayMetrics.filter((metric) => metric.type === 'NettAppointments')[0].value;
    const yesterday = userMetrics?.metricData.pastMetrics.filter((metric) => metric.type === 'NettAppointments')[0].value;
    return +today! - +yesterday!;
  };

  return (
    <>
      {!showHeader && <Header />}
      <div className="activity-page">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 className="activity-title">{strings.reportingActivity}</h3>
          <Button onClick={() => fetchData()} type="link">
            <SyncOutlined style={{ fontSize: '20px', color: 'grey' }} spin={isSpinning} />
          </Button>
        </div>
        <Card className="activity-card" style={{ paddingLeft: '5px', paddingRight: '30px' }}>
          {userMetrics?.metricData?.todayMetrics?.filter((action) => action.type === 'WentOffline') &&
            userMetrics?.metricData?.todayMetrics?.filter((action) => action.type === 'WentOffline').length >= 3 && (
              <section className="warning-section">
                <div className="warning-message">
                  <ExclamationOutlined style={{ color: 'red', paddingLeft: '5px', paddingRight: '10px' }} />
                  {strings.reportingWarningMessage}
                </div>
              </section>
            )}
          <Row gutter={16}>
            <div style={{ paddingLeft: '53px', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Col span={5}>
                <Statistic title={<b>{strings.reportingBookings}</b>} value={bookings ?? 0} valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }} />
                <span>
                  {calculateBookingsDiff() > 0 ? (
                    <CaretUpOutlined style={{ color: 'green' }} />
                  ) : calculateBookingsDiff() < 0 ? (
                    <CaretDownOutlined style={{ color: 'red' }} />
                  ) : (
                    <MinusOutlined style={{ color: 'gray' }} />
                  )}
                  {calculateBookingsDiff()} {strings.reportingVsYesterday}{' '}
                  <Tooltip title={strings.reportingComparedToSameTimeYesterday}>
                    {' '}
                    <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                  </Tooltip>
                </span>
              </Col>
              <Col span={4}>
                <Statistic
                  title={<b>{strings.reportingLeadTime}</b>}
                  value={(sameNextDayBooking! * 100).toFixed(1) ?? 0}
                  suffix={sameNextDayBooking === 0 ? '' : '%'}
                  valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }}
                />
                <span>
                  {calculateNearestAppPercentage() > 0 ? (
                    <CaretUpOutlined style={{ color: 'green' }} />
                  ) : calculateNearestAppPercentage() < 0 ? (
                    <CaretDownOutlined style={{ color: 'red' }} />
                  ) : (
                    <MinusOutlined style={{ color: 'gray' }} />
                  )}
                  {(calculateNearestAppPercentage() * 100).toFixed(1)}% {strings.reportingVsYesterday}{' '}
                  <Tooltip title={strings.reportingComparedToSameTimeYesterday}>
                    {' '}
                    <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                  </Tooltip>
                </span>
              </Col>
              <Col span={4}>
                <Statistic
                  title={<b>{strings.reportingReachedRate}</b>}
                  value={userActionCount}
                  suffix={userActionCount === 0 ? '' : '%'}
                  valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }}
                />

                <span>
                  {calculateReachedRate() > 0 ? (
                    <CaretUpOutlined style={{ color: 'green' }} />
                  ) : calculateReachedRate() < 0 ? (
                    <CaretDownOutlined style={{ color: 'red' }} />
                  ) : (
                    <MinusOutlined style={{ color: 'gray' }} />
                  )}
                  {(calculateReachedRate() * 100).toFixed(1)}% {strings.reportingVsYesterday}{' '}
                  <Tooltip title={strings.reportingComparedToSameTimeYesterday}>
                    {' '}
                    <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                  </Tooltip>
                </span>
              </Col>
              <Col span={4}>
                <Statistic
                  title={<b>{strings.reportingBookingRate}</b>}
                  value={(calculateBookingRateToday() * 100).toFixed(1)}
                  suffix="%"
                  valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }}
                />
                <span>
                  {calculateBookingsRateDiff() > 0 ? (
                    <CaretUpOutlined style={{ color: 'green' }} />
                  ) : calculateBookingsRateDiff() < 0 ? (
                    <CaretDownOutlined style={{ color: 'red' }} />
                  ) : (
                    <MinusOutlined style={{ color: 'gray' }} />
                  )}
                  {(calculateBookingsRateDiff() * 100).toFixed(1)}% {strings.reportingVsYesterday}{' '}
                  <Tooltip title={strings.reportingComparedToSameTimeYesterday}>
                    {' '}
                    <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                  </Tooltip>
                </span>
              </Col>
              <Col span={4}>
                <Statistic
                  title={<b>{strings.reportingShowRate}</b>}
                  value={calculateShowRateForUser()}
                  suffix={showRate === 0 ? '' : '%'}
                  valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }}
                />
                <span>
                  {getNextUpdateTimeForShowRate()}
                  <Tooltip title={getShowRateClarification()}>
                    {' '}
                    <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                  </Tooltip>
                </span>
              </Col>
              <Col span={4}>
                <Statistic
                  title={<b>{strings.reportingNettermine}</b>}
                  value={userMetrics?.metricData.todayMetrics.filter((metric) => metric.type === 'NettAppointments')[0].value}
                  valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }}
                />
                <span>
                  {calculateNettoTerminen() > 0 ? (
                    <CaretUpOutlined style={{ color: 'green' }} />
                  ) : calculateNettoTerminen() < 0 ? (
                    <CaretDownOutlined style={{ color: 'red' }} />
                  ) : (
                    <MinusOutlined style={{ color: 'gray' }} />
                  )}
                  {calculateNettoTerminen()} {strings.reportingVsYesterday}{' '}
                  <Tooltip title={strings.reportingComparedToSameTimeYesterday}>
                    {' '}
                    <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                  </Tooltip>
                </span>
              </Col>
            </div>
            <Col span={24}>
              <Title level={5} style={{ marginBottom: '20px', marginLeft: '20px' }}>
                {strings.reportingCallPerHour}
              </Title>
              <LineChart
                h={200}
                data={
                  userMetrics?.hourlyData.todayHourlyData?.map((el) => ({
                    hour: +el.hour,
                    amount: el.amount,
                  })) || []
                }
                dataKey="hour"
                series={[{ name: 'amount', color: 'blue.6', label: strings.reportingCallPerHour }]}
                curveType="linear"
              />
            </Col>
          </Row>
        </Card>
        <Select
          options={[
            { value: 'dep', label: strings.reporting.lblDepView },
            { value: 'team', label: strings.reporting.lblTeamView },
          ]}
          defaultValue={strings.reporting.lblDepView}
          style={{ marginBottom: '10px', width: '200px' }}
          onChange={(value) => fetchRatingData(value)}
        />
        <Row gutter={16} className="ranking-section">
          <Col span={6}>
            <RankingCard title={strings.reportingTopBookersToday} rankData={ratingData?.topBookers ?? null} />
          </Col>
          <Col span={6}>
            <RankingCard title={strings.reportingTopCallersToday} rankData={convertDataToPercentageRating(ratingData?.nearestAppointmentsPerToday ?? null)} />
          </Col>
          <Col span={6}>
            <RankingCard title={strings.reportingHighestBookingRateToday} rankData={convertDataToPercentageRating(ratingData?.mostBookingsPerAssignment ?? null)} />
          </Col>
          <Tooltip title={strings.reportingShowRateClarification}>
            <Col span={6}>
              <RankingCard title={strings.reportingShowRateLeaderboard} rankData={convertDataToPercentageRating(ratingData?.topShowRate ?? null)} />
            </Col>
          </Tooltip>
        </Row>
      </div>
    </>
  );
};

export default ReportingPage;
