import { Modal } from 'antd';
import { strings } from '../../../lang';
import { useState } from 'react';
import { DoNotCloseTag } from '../../common/donotclose-tag';

export interface NotReachedModalProps {
  open: boolean;
  onOk: () => Promise<void>;
  onCancel: () => void;
}

export const NotReachedModal = ({ open, onOk, onCancel }: NotReachedModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onOkPressed = async () => {
    try {
      setIsLoading(true);
      await onOk();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={strings.modalTitleNotReached}
      centered
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      keyboard={false}
      open={open}
      onOk={onOkPressed}
      okText={strings.saveAndOpenNextCase}
      cancelText={strings.cancel}
      onCancel={onCancel}
      okButtonProps={{ disabled: isLoading, loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <p>{strings.modalTextNotReached}</p>
      {isLoading && <DoNotCloseTag />}
    </Modal>
  );
};
