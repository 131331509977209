import React, { useState } from "react";
import { EditContactContainer } from "./edit-contacts";
import { Button, Input, notification } from "antd";

export const EditContactPage = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const [password, setPassword] = useState("");

  //if we keep this page more than a month, lets change this
  const VERY_VERY_VERY_SECRET_PASSWORD = "PinnapleGoesOnPizza!1";

  const handleSubmit = () => {
    if (password === VERY_VERY_VERY_SECRET_PASSWORD) {
      setHasAccess(true);
    } else {
      notification.error({
        message: "Access Denied",
        description: "Incorrect password. Please try again.",
      });
      setPassword("");
    }
  };

  return (
    <div className="edit-contact-page-container">
      {hasAccess ? (
        <EditContactContainer />
      ) : (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "2rem" }}>
          <h2>Please enter your password</h2>
          <Input.Password
            style={{ maxWidth: "300px", marginBottom: "1rem" }}
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};