import './case-list-page.css';
import { useEffect, useState } from 'react';
import { Header } from '../header/header';
import { TotalOpportunities } from '../../dto/model';
import { useMsal } from '@azure/msal-react';
import { scopes } from '../../authConfig';
import { GetAccessToken } from '../../utils/auth-utils';
import { bookingPageService } from '../../services/booking-page-service';
import { Button, Skeleton, Table, Statistic, Row, Typography, notification } from 'antd';
import { handleDownloadAll } from '../../utils/file-utils';
import { reportingService } from '../../services/reporting-service';
import { DateTimePicker } from '@mantine/dates';
import { AnalyticsDownload } from '../admin-page/components/analytics/analytics-download';

export const CaseListPage = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [totalOpportunities, setTotalOpportunities] = useState<TotalOpportunities>();
  const [isLoading, setIsLoading] = useState(false);
  const [isGamificationLoading, setIsGamificationLoading] = useState(false);
  const [gamifiedUsersCount, setGamifiedUsersCount] = useState<number | string>('Calculating...');
  const [totalOppsCount, setTotalOppsCount] = useState<number | string>('Calculating...');

  useEffect(() => {
    (async () => {
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const totalOpps = await bookingPageService.getAllCases(token!.accessToken, true);
      setTotalOpportunities(totalOpps);
      setTotalOppsCount(totalOpps ? totalOpps.count : 0);
      const gamifiedUsers = await reportingService.getGamifiedUsersCountAsync(token.accessToken);
      setGamifiedUsersCount(gamifiedUsers);
    })();
  }, []);

  const downloadOpps = async () => {
    setIsLoading(true);
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);

    const totalOps = await bookingPageService.getAllCases(token!.accessToken, false);
    handleDownloadAll(totalOps.opportunities, 'opportunity_queue_bookingos');
    setIsLoading(false);
  };

  const downloadReport = async () => {
    setIsGamificationLoading(true);
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    await reportingService.downloadReport(token.accessToken);
    setIsGamificationLoading(false);
  };

  const downloadContactEdits = async () => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const editContactsResult = await bookingPageService.getContactsToEdit(token.accessToken, null);
    if (!editContactsResult.isSuccess) {
      notification.error({
        message: 'Error',
        description: 'Could not download the contacts to edit',
      })
      return;
    }
    handleDownloadAll(editContactsResult.data, 'contacts_to_edit');
  }

  return (
    <>
      <Header />
      <div className="container">
        <h4>Opportunity Queue</h4>
        <div className="clp-horizontal-div">
          <Statistic title="Number of Callable Opportunities" value={totalOppsCount} valueStyle={{ fontSize: 16 }} style={{ alignSelf: 'center', margin: 5 }} />
          <Button className="clp-download-btn" onClick={downloadOpps} loading={isLoading}>
            Download Opportunity Queue
          </Button>
        </div>
      </div>
      <div className="container">
        <h4>Gamification User Progress</h4>
        <div className="clp-horizontal-div">
          <Statistic title="Number of Gamification Users" value={gamifiedUsersCount} valueStyle={{ fontSize: 16 }} style={{ alignSelf: 'center', margin: 5 }} />
          <Button className="clp-download-btn" onClick={downloadReport} loading={isGamificationLoading}>
            Download User Progress
          </Button>
        </div>
      </div>
      <div className="container">
        <h4>Slot Analytics</h4>
        <div className="clp-horizontal-div">
          <Typography.Text type="secondary" style={{ marginBottom: '1rem', display: 'block' }}>
            Download all the slot analytics from today until 3 weeks in the future
          </Typography.Text>
          <AnalyticsDownload />
        </div>
      </div>
      <div className="container">
        <h4>Contacts to Edit</h4>
        <div className="clp-horizontal-div">
          <Typography.Text type="secondary" style={{ marginBottom: '1rem', display: 'block' }}>
            Download all the contacts that need to be edited in salesforce.
          </Typography.Text>
          <Button className="clp-download-btn" onClick={downloadContactEdits} loading={isGamificationLoading}>
            Download Edit Contacts
          </Button>
        </div>
      </div>
    </>
  );
};
