import { Tag } from "antd"
import { strings } from "../../lang"

export const DoNotCloseTag = () => {

  return (
    <p>
      <b>
        <Tag color="processing" style={{ padding: '5px 10px', fontSize: '14px' }}>
          {strings.dontCloseModal}
        </Tag>
      </b>
    </p>
  )
}