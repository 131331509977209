import { Button, Form, Input, Select } from 'antd';
import { TeamMemberModel, TeamModel, UserModel } from '../../dto/reporting-models';
import { useState } from 'react';

interface TeamFormProps {
  team: TeamModel;
  teamMembers: TeamMemberModel[];
  users: UserModel[];
  onSubmit: (team: TeamModel, members: TeamMemberModel[]) => void;
  cancel: () => void;
}

export const TeamForm = ({ team, teamMembers, users, onSubmit, cancel }: TeamFormProps) => {
  const [form] = Form.useForm();
  const [currentTeam, setCurrentTeam] = useState<TeamModel>(team);
  const [currentMembers, setCurrentMembers] = useState<TeamMemberModel[]>(teamMembers);

  function onFinish(): void {
    onSubmit(currentTeam, currentMembers);
  }

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" style={{ marginBottom: '20px' }}>
      <Form.Item required label="Name" name="name" initialValue={currentTeam.name}>
        <Input placeholder="Team name" onChange={(e) => setCurrentTeam({ ...currentTeam, name: e.target.value })} />
      </Form.Item>
      <Form.Item required label="Team Lead" name="lead" initialValue={users.find((user) => user.id === currentTeam.leadId)?.fullName}>
        <Select
          showSearch={true}
          value={currentTeam.leadId}
          placeholder="Select team lead"
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          onChange={(value) => setCurrentTeam({ ...currentTeam, leadId: value })}
          options={users.map((user) => {
            return { label: user.fullName, value: user.id };
          })}
        />
      </Form.Item>
      <Form.Item
        label="Team members"
        name="members"
        initialValue={currentMembers
          .filter((user) => user.id !== currentTeam.leadId)
          .map((member) => {
            return { label: member.fullName, value: member.id };
          })}
      >
        <Select
          placeholder="Select team members"
          value={currentMembers.filter((user) => user.id !== currentTeam.leadId).map((member) => member.id)}
          mode="multiple"
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          onChange={(selectedIds) => {
            const updatedMembers = selectedIds.map((id) => {
              const user = users.find((user) => user.id === id);
              return { id, fullName: user?.fullName || '' };
            });
            setCurrentMembers(updatedMembers);
          }}
          options={users
            .filter((user) => user.id !== currentTeam.leadId)
            .map((user) => {
              return { label: user.fullName, value: user.id };
            })}
        />
      </Form.Item>
      <div className="bg-gray-50 p-4 rounded-md" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button onClick={cancel}>Cancel</Button>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};
