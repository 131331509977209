import { Button, Checkbox, Form, Input, Select, Space, Tooltip } from "antd";
import { CustomerRule, CustomerRuleOutcome, MethodName, RuleAction, RuleFor } from "../../../../dto/admin-rule-models";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { SalesChannel } from "../../../../dto/model";
import { InfoCircleOutlined } from "@ant-design/icons";

type RuleTabProps = {
  rule: CustomerRule
  setRule: (rule: CustomerRule) => void
  tabPane: RuleFor
}

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

export const OutcomeTab = ({ rule, setRule, tabPane }: RuleTabProps) => {

  const addOutcome = (ruleFor: RuleFor) => {
    const newOutcomes = [...rule.outcomes, {
      feId: crypto.randomUUID(),
      action: RuleAction.None,
      actionValue: '',
      methodNames: rule.conditions[0]?.methodNames,
      ruleFor: ruleFor
    }];
    const updatedRule = {
      ...rule,
      outcomes: newOutcomes
    }

    setRule(updatedRule);
  }

  const removeOutcome = (feId: string) => {
    const updatedOutcomes = rule.outcomes.filter(outcome => outcome.feId != feId);
    const updatedRule = {
      ...rule,
      outcomes: updatedOutcomes
    }

    setRule(updatedRule);
  }

  const updateOutcome = (outcome: CustomerRuleOutcome) => {
    const updatedOutcomes = rule.outcomes.map(o => o.feId == outcome.feId ? outcome : o);
    const updatedRule = {
      ...rule,
      outcomes: updatedOutcomes
    }
    setRule(updatedRule);
  }

  const getRuleMethodOptions = (ruleFor: RuleFor) => {
    const newOptions = (() => {
      switch (ruleFor) {
        case RuleFor.OpportunitySync:
          return [
            { label: 'Opportunity Sync', value: MethodName.SYNC_OPPORTUNITY }];
        case RuleFor.CalendarDisplay:
          return [
            { label: 'SST Calendar', value: MethodName.GET_SST, style: {} },
            { label: 'Agents Calendar', value: MethodName.GET_CALENDAR, style: {} },];
        case RuleFor.SlotBooking:
          return [
            { label: 'SST Booking', value: MethodName.BOOK_SST, style: {} },
            { label: 'Agents Booking', value: MethodName.BOOK_CALENDAR, style: {} }];
        case RuleFor.Scripts:
          return [
            { label: 'Opportunity Agent View', value: MethodName.INFO_OPPORTUNITY }];
        default:
          return [];
      }
    })();

    return newOptions;
  };

  const onMethodNameChange = (value: MethodName[], ruleFor: RuleFor) => {
    const updatedConditions = rule.conditions.map(condition => {
      return {
        ...condition,
        methodNames: value
      };
    });
    const updatedOutcomes = rule.outcomes.map(outcome => {
      if (outcome.ruleFor === ruleFor) {
        return {
          ...outcome,
          methodNames: value
        };
      }
      return outcome;
    });

    const updatedRule = {
      ...rule,
      conditions: updatedConditions,
      outcomes: updatedOutcomes
    };
    setRule(updatedRule);
  }

  const ruleActionOptions = (ruleFor: RuleFor) => {
    switch (ruleFor) {
      case RuleFor.OpportunitySync:
        return [
          { label: 'Set IsDeleted', value: RuleAction.SetIsDeleted },
          { label: 'Set Sales Channel', value: RuleAction.SetSalesChannel },
          { label: 'Set Initial Snooze', value: RuleAction.SetDoNotCallBefore },
        ];
      case RuleFor.CalendarDisplay:
        return [
          { label: 'Add Buffer Before First Slot', value: RuleAction.SetHoursInAdvance },
          { label: 'Filter For Seller Azure Ids', value: RuleAction.UseSellerIds },
          { label: 'Filter For Seller Products', value: RuleAction.UseSellerProducts },
          { label: 'Filter For Seller Payment Models', value: RuleAction.PreferredPayment }
        ];
      case RuleFor.SlotBooking:
        return [
          { label: 'Filter For Seller Azure Ids', value: RuleAction.UseSellerIds },
          { label: 'Filter For Seller Products', value: RuleAction.UseSellerProducts },
          { label: 'Filter For Seller Payment Models', value: RuleAction.PreferredPayment },
          { label: 'Preferably Book Seller Products', value: RuleAction.PreferredProduct }
        ];
      case RuleFor.Scripts:
        return [
          { label: 'Use Script', value: RuleAction.SetOpportunityScript }
        ]
    }
  }

  const getDynamicOutcomeValue = (outcome: CustomerRuleOutcome) => {
    switch (outcome.action) {
      case RuleAction.SetHoursInAdvance:
        return <Input
          placeholder="Hours"
          value={outcome.actionValue}
          type="number"
          onChange={(e) => { outcome.actionValue = e.target.value.toString(); updateOutcome(outcome) }}
        />
      case RuleAction.SetDoNotCallBefore:
        return <div> <Input
          style={{ width: 50, marginRight: 10 }}
          placeholder="Value"
          value={outcome.actionValue.split(' ').filter(x => !isNaN(Number(x)))[0]}
          onChange={(e) => { outcome.actionValue = `${e.target.value} ${outcome.actionValue.split(' ')[1]}`; updateOutcome(outcome) }}
        />
          <Select
            style={{ width: 160 }}
            placeholder="Value"
            value={outcome.actionValue.split(' ').filter(x => isNaN(Number(x)))[0]}
            onChange={(value) => { outcome.actionValue = `${outcome.actionValue.split(' ')[0]} ${value}`; updateOutcome(outcome) }}
          >
            <Option value='m'>Minutes</Option>
            <Option value='H'>Hours</Option>
            <Option value='D'>Days</Option>
            <Option value='BD'>Days (Beginning of Day)</Option>
          </Select>
        </div>
      case RuleAction.UseSellerIds:
        return <TextArea
          placeholder="Value"
          value={outcome.actionValue}
          rows={1}
          onChange={(e) => { outcome.actionValue = e.target.value; updateOutcome(outcome) }}
        />
      case RuleAction.UseSellerProducts:
        return <Select
          placeholder="Value"
          value={outcome.actionValue}
          onChange={(value) => { outcome.actionValue = value.toString(); updateOutcome(outcome) }}
        >
          <Option value='1'>PV</Option>
          <Option value='3'>COMBINED</Option>
          <Option value='4'>HEATPUMP</Option>
        </Select>
      case RuleAction.SetIsDeleted:
        return <Select
          placeholder="Value"
          value={outcome.actionValue}
          onChange={(value) => { outcome.actionValue = value; updateOutcome(outcome) }}
        >
          <Option value="true">True</Option>
          <Option value="false">False</Option>
        </Select>
      case RuleAction.SetSalesChannel:
        return <>
          <Select
            placeholder="Value"
            value={outcome.actionValue}
            onChange={(value) => { outcome.actionValue = value.toString(); updateOutcome(outcome) }}
          >
            {Object.entries(SalesChannel).filter(x => x[0] != SalesChannel.MissingQueue).map(([key, value]) => (
              <Option key={key} value={value}>
                {key}
              </Option>
            ))}
          </Select>
        </>
      case RuleAction.PreferredProduct:
        return <Select
          placeholder="Value"
          value={outcome.actionValue}
          onChange={(value) => { outcome.actionValue = value.toString(); updateOutcome(outcome) }}
        >
          <Option value='1'>PV</Option>
          <Option value='3'>COMBINED</Option>
          <Option value='4'>HEATPUMP</Option>
        </Select>
      case RuleAction.PreferredPayment:
        return <Select
          placeholder="Value"
          value={outcome.actionValue}
          onChange={(value) => { outcome.actionValue = value.toString(); updateOutcome(outcome) }}
        >
          <Option value='0'>ANY</Option>
          <Option value='1'>LEASE</Option>
          <Option value='2'>LOAN</Option>
        </Select>
      case RuleAction.SetOpportunityScript:
        return <Input
          placeholder="Value"
          value={outcome.actionValue}
          onChange={(e) => { outcome.actionValue = e.target.value; updateOutcome(outcome) }}
        />

      default:
        return null;
    }
  }

  const getDynamicToolTipValue = (outcome: CustomerRuleOutcome) => {
    switch (outcome.action) {
      case RuleAction.SetHoursInAdvance:
        return <Tooltip title="This will filter the slots shown to customers and/or booking agents. If set to 1 hour, no slots starting within 1 hour from now will be shown" >
          <InfoCircleOutlined />
        </Tooltip>
      case RuleAction.SetDoNotCallBefore:
        return <Tooltip title="Define how much time should pass between sign-up and the first call to the customer. Note that the first call may come later than defined depending on agent availability.">
          <InfoCircleOutlined />
        </Tooltip>
      case RuleAction.UseSellerIds:
        return <Tooltip title="Add sellers using their Azure Id, separated by commas. This filter is applied in addition to other rules for filtering sellers (e.g. products). It will show exclusively the specified sellers.">
          <InfoCircleOutlined />
        </Tooltip>
      case RuleAction.UseSellerProducts:
        return <Tooltip title="Select 1 or more products to filter for sellers who can sell the selected product(s) (specified on the Salesforce user object for Digital Sales sellers, or in SalesOS for field sellers).">
          <InfoCircleOutlined />
        </Tooltip>
      case RuleAction.SetIsDeleted:
        return <Tooltip title="Set the IsDeleted flag on the opportunity. This will hide the opportunity from the customer journey.">
          <InfoCircleOutlined />
        </Tooltip>
      case RuleAction.SetSalesChannel:
        return <Tooltip title="Select a sales channel for the opportunity.">
          <InfoCircleOutlined />
        </Tooltip>
      case RuleAction.PreferredProduct:
        return <Tooltip title="Select 1 or more products to preferably route this opportunity to sellers who can sell the selected product(s) (specified on the Salesforce user object for Digital Sales sellers, or in SalesOS for field sellers).">
          <InfoCircleOutlined />
        </Tooltip>
      case RuleAction.PreferredPayment:
        return <Tooltip title="Select 1 or more payment models to filter for sellers who can sell the selected payment model(s) (specified on the Salesforce user object for Digital Sales sellers, or in SalesOS for field sellers).">
          <InfoCircleOutlined />
        </Tooltip>
      default:
        return null;
    }
  }

  const onSelectActionChange = (value: RuleAction, outcome: CustomerRuleOutcome) => {
    const updatedAction = {
      ...outcome,
      action: value,
      actionValue: ''
    };
    updateOutcome(updatedAction);
  }

  return (
    <div className="bg-gray-50 p-4 rounded-md">

      <Tooltip title={rule.outcomes.filter(x => x.ruleFor == tabPane).length === 0 ? "You need to add an outcome" : ""}>
        <CheckboxGroup
          options={getRuleMethodOptions(tabPane)}
          style={{ marginBottom: 30, marginLeft: 10 }}
          value={rule.outcomes.filter(x => x.ruleFor == tabPane)[0]?.methodNames ?? []}
          onChange={(value) => onMethodNameChange(value, tabPane)}
          disabled={rule.outcomes.filter(x => x.ruleFor == tabPane).length === 0}
        />
      </Tooltip>
      {
        rule?.outcomes
          ?.filter(outcome => outcome.ruleFor == tabPane)
          ?.map((outcome) => (
            <Space key={outcome.feId} align="start" className="w-full">
              <Form.Item
                name={['outComeActionSelect', `${outcome.feId}`]}
                rules={[{ required: true, message: 'Please select an outcome action!' }]}
                style={{ marginLeft: 10, width: 240, marginTop: 5 }}
                initialValue={outcome.action == RuleAction.None ? null : outcome.action}
              >
                <Select
                  onChange={(value) => onSelectActionChange(value, outcome)}
                  value={outcome.action == RuleAction.None ? null : outcome.action}
                >
                  {ruleActionOptions(tabPane)
                    .map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={['outcomeValue', `${outcome.feId}`]}
                initialValue={outcome.actionValue}
                style={{ width: 220, marginTop: 5 }}
                getValueProps={(value) => {
                  const valueToReturn = (isNaN(Number(outcome.actionValue)) && dayjs(outcome.actionValue).isValid())
                    ? dayjs(outcome.actionValue).toDate()
                    : outcome.actionValue;
                  return { value: valueToReturn };
                }}
                rules={[{ required: true, message: 'Please input the do outcome action!' }]}
              >
                {getDynamicOutcomeValue(outcome)}
              </Form.Item>
              <div style={{ marginTop: 10 }}>
                {getDynamicToolTipValue(outcome)}
              </div>
              <Button onClick={() => removeOutcome(outcome.feId)} style={{ alignSelf: 'start', marginTop: 5 }}>Delete</Button>
            </Space>
          ))
      }
      <Tooltip title={rule?.conditions?.length == 0 ? "Add an outcome only if there is at least one condition" : ""}>
        <Button disabled={rule?.conditions?.length == 0} type="dashed" block className="my-4" onClick={() => addOutcome(tabPane)}>
          + Add Outcome
        </Button>
      </Tooltip>
    </div >
  )
};