import './admin-page.css';
import './admin-page.css';

import { Header } from '../header/header';
import { Menu } from 'antd';
import { useState } from 'react';
import type { MenuProps } from 'antd';
import { ScriptPage } from '../script-editor/script-page';
import AdminTopBookers from './components/top-bookers/admin-top-bookers';
import { AdminCustomerRule } from './components/customer-rule/customer-rule';
import AdminSnoozeConfig from './components/snooze-config/admin-snooze-config';
import AdminTranscription from './components/transcription/admin-transcription';
import { AnalyticsDownload } from './components/analytics/analytics-download';
import { AdminAchievements } from './components/live-reporting-config/achievements';
import { AdminExperience } from './components/live-reporting-config/experience-points';
import { EditContactContainer } from './components/edit-contacts/edit-contacts';

type MenuItem = Required<MenuProps>['items'][number];

export const AdminPage = () => {
  const [selectedKey, setSelectedKey] = useState<string>('1');

  const items: MenuItem[] = [
    {
      key: 1,
      label: 'Snooze Config',
    },
    {
      key: 2,
      label: 'Scripts',
    },
    {
      key: 3,
      label: 'Transcription',
    },
    {
      key: 4,
      label: 'Top Bookers',
    },
    {
      key: 5,
      label: 'Customer Rules',
    },
    {
      key: 7,
      label: 'Experience Points',
    },
    {
      key: 8,
      label: 'Achievements',
    },
    {
      key: 9,
      label: 'Edit Contacts',
    }
  ];

  return (
    <>
      <Header />
      <div className="flex-admin-container">
        <Menu
          defaultSelectedKeys={['1']}
          mode="inline"
          style={{ width: '11%' }}
          onSelect={(item) => {
            setSelectedKey(item.key.toString());
          }}
          items={items}
        ></Menu>
        {selectedKey === '1' && <AdminSnoozeConfig />}
        {selectedKey === '2' && <ScriptPage />}
        {selectedKey === '3' && <AdminTranscription />}
        {selectedKey === '4' && <AdminTopBookers />}
        {selectedKey === '5' && <AdminCustomerRule />}
        {selectedKey === '7' && <AdminExperience />}
        {selectedKey === '8' && <AdminAchievements />}
        {selectedKey === '9' && <EditContactContainer />}
      </div>
    </>
  );
};
