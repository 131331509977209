import { SalesChannel } from "./model";

export interface CustomerRule {
  id: number;
  feId: string;
  title: string;
  description: string;
  createdDate: Date;
  lastModifiedDate: Date;
  createdBy: string;
  isDeleted: boolean;
  salesChannels: SalesChannel[];
  conditions: CustomerRuleCondition[];
  outcomes: CustomerRuleOutcome[];
}

export interface CustomerRuleCondition {
  feId: string;
  condition: RuleCondition;
  propertyName: string;
  value: string;
  methodNames: MethodName[];
}

export interface CustomerRuleOutcome {
  feId: string;
  action: RuleAction;
  actionValue: string;
  methodNames: MethodName[];
  ruleFor: RuleFor;
}

export enum RuleAction {
  None = 'None',
  SetHoursInAdvance = 'SetHoursInAdvance',
  SetIsDeleted = 'SetIsDeleted',
  SetDoNotCallBefore = 'SetDoNotCallBefore',
  UseSellerIds = 'UseSellerIds',
  UseSellerProducts = 'UseSellerProducts',
  SetSalesChannel = 'SetSalesChannel',
  PreferredProduct = 'PreferredProduct',
  PreferredPayment = 'PreferredPayment',
  SetOpportunityScript = 'SetOpportunityScript',
}

export enum RuleCondition {
  None = 'None',
  Equals = 'Equals',
  Contains = 'Contains',
  StartsWith = 'StartsWith',
  Regex = 'Regex',
}

export enum MethodName {
  NONE = 'NONE',
  GET_CALENDAR = 'GET_CALENDAR',
  GET_SST = 'GET_SST',
  GET_SST_EMBEDDED = 'GET_SST_EMBEDDED',
  BOOK_CALENDAR = 'BOOK_CALENDAR',
  BOOK_SST = 'BOOK_SST',
  BOOK_SST_EMBEDDED = 'BOOK_SST_EMBEDDED',
  CANCEL_SST = 'CANCEL_SST',
  BOOK_SST_ENPAL24 = 'BOOK_SST_ENPAL24',
  GET_SST_ENPAL24 = 'GET_SST_ENPAL24',
  GET_SST_CRM = 'GET_SST_CRM',
  BOOK_SST_CRM = 'BOOK_SST_CRM',
  CANCEL_INBOUND_CENTER = 'CANCEL_INBOUND_CENTER',
  BOOK_CALENDAR_INBOUND_CENTER = 'BOOK_CALENDAR_INBOUND_CENTER',
  ASSIGN_INBOUND_CENTER = 'ASSIGN_INBOUND_CENTER',
  STANDALONE_CALENDAR = 'STANDALONE_CALENDAR',
  GET_CALENDAR_INBOUND_CENTER = 'GET_CALENDAR_INBOUND_CENTER',
  GET_CALENDAR_STANDALONE = 'GET_CALENDAR_STANDALONE',
  BOOK_CALENDAR_STANDALONE = 'BOOK_CALENDAR_STANDALONE',
  BOOK_CALENDAR_STANDALONE_SC2 = 'BOOK_CALENDAR_STANDALONE_SC2',
  GET_CALENDAR_STANDALONE_SC2 = 'GET_CALENDAR_STANDALONE_SC2',
  SYNC_OPPORTUNITY = 'SYNC_OPPORTUNITY',
  INFO_OPPORTUNITY = 'INFO_OPPORTUNITY',
}

export enum RuleFor {
  OpportunitySync = 'OpportunitySync',
  CalendarDisplay = 'CalendarDisplay',
  SlotBooking = 'SlotBooking',
  Scripts = 'Scripts',
}

export const opportunityValues: { [key: string]: string } = {
  'LeadSource': 'Lead Source',
  'LeadCampaign': 'Lead Campaign',
  'PilotTest': 'Pilot Test',
  'RoofCovering': 'Roof Covering',
  'ProductInterest': 'Product Interest',
  'ABTestingFragment': 'AB Testing Fragment',
};
