import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AppointmentType, Lead, Result, SalesManagerAvailability, Slot, LeadTier, Calendar, SlotConflict, ScriptsWithObjections } from '../dto/model';

dayjs.extend(customParseFormat);

const rootUrl = (process as any).env.REACT_APP_API_ROOT_URL;

const handleResponse = async (url: string, options: RequestInit): Promise<Response> => {
  const response = await fetch(url, options);

  if (!response.ok) {
    throw {
      status: response.status,
      statusText: response.statusText,
    };
  }

  return response;
};

export const salesAppointmentsService = {
  async getLead(gcid: string, salesChannel: string, accessToken: string): Promise<Lead> {
    let url = `${rootUrl}/api/v1/lead/${gcid}?salesChannel=${salesChannel}`;
    const response = await handleResponse(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    const result = await response.json();
    return result.data;
  },

  async getLatestSlot(accessToken: string, opportunityId: string, salesChannel: string): Promise<Result<Slot>> {
    const url = `${rootUrl}/api/v1/slots?opportunityId=${opportunityId}&salesChannel=${salesChannel}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();
    return responseJson;
  },

  async getAvailableSlots(calendarWeek: string, opportunityId: string, salesChannel: string, accessToken: string, sourceSystemHeader?: string): Promise<Calendar> {
    //Do some cleaning when local storage gets too big
    if (window.localStorage.length > 1000) {
      window.localStorage.clear();
    }
    const uniqueVisitObj = JSON.parse(window.localStorage.getItem(opportunityId) || '{}');
    const currentTime = new Date().getTime();

    // Check if 15 minutes has passed since the uniqueVisit was set
    if (uniqueVisitObj.time && currentTime - uniqueVisitObj.time > 15 * 60 * 1000) {
      window.localStorage.removeItem('uniqueVisit');
      uniqueVisitObj.time = null;
    }

    const url = `${rootUrl}/api/v1/calendar/${calendarWeek}?opportunityId=${opportunityId}&salesChannel=${salesChannel}`;

    const headersToUse: { [key: string]: string } = {
      UniqueVisit: !uniqueVisitObj.time && opportunityId ? 'true' : 'false',
      Authorization: `Bearer ${accessToken}`,
    };
    if (sourceSystemHeader) {
      headersToUse['sourceui'] = sourceSystemHeader;
    }

    const response = await handleResponse(url, {
      method: 'GET',
      headers: headersToUse,
    });
    const calendar = (await response.json()).data;

    //Set the unique visit time as all other visits will not be unique
    if (!uniqueVisitObj.time) {
      uniqueVisitObj.time = currentTime;
      window.localStorage.setItem(opportunityId, JSON.stringify(uniqueVisitObj));
    }

    const res: Calendar = {
      ...calendar,
      slots: calendar.slots.map((x: any) => ({
        ...x,
        startDate: dayjs(x.startDate),
        endDate: dayjs(x.endDate),
      })),
    };
    res.slots = res.slots.filter((s) => s.startDate.isoWeekday() !== 7);

    return res;
  },

  async getSalesManagersAvailability(date: Dayjs, appointmentType: AppointmentType, salesChannel: string, accessToken: string): Promise<SalesManagerAvailability[]> {
    const url = `${rootUrl}/api/v1/sales-manager/availability/${date.utc().format()}/${appointmentType}?salesChannel=${salesChannel}`;
    const response = await handleResponse(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    return (await response.json()).data;
  },

  async bookSlot(opportunityId: string, slotDate: Dayjs, salesChannel: string, accessToken: string, sourceSystemHeader: string, bookingTypeHeader?: string): Promise<Result<Slot>> {
    const url = `${rootUrl}/api/v2/slot?salesChannel=${salesChannel}`;

    const headersToUse: { [key: string]: string } = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    if (sourceSystemHeader) {
      headersToUse['sourceui'] = sourceSystemHeader;
    }
    if (bookingTypeHeader) {
      headersToUse['booking-type'] = bookingTypeHeader;
    }

    const requestOptions = {
      method: 'PUT',
      headers: headersToUse,
      body: JSON.stringify({
        opportunityId,
        slotDate: slotDate.utc().format(),
      }),
    };
    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();
    return responseJson;
  },

  async getSlotConflicts(startDate: Dayjs, endDate: Dayjs, salesChannel: string, accessToken: string): Promise<Result<SlotConflict[]>> {
    const url = `${rootUrl}/api/v1/slot/conflicts/${startDate.format('YYYY-MM-DD')}/${endDate.format('YYYY-MM-DD')}?salesChannel=${salesChannel}`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await handleResponse(url, requestOptions);
    return await response.json();
  },

  async rebookSlot(slotId: number, salesChannel: string, accessToken: string, oppId: string): Promise<Result<string>> {
    const url = `${rootUrl}/api/v1/slots/${slotId}/rebook?opportunityId=${oppId}&salesChannel=${salesChannel}`;
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(url, requestOptions);
    if (response.status === 403) {
      throw {
        status: 403,
        statusText: 'Forbidden',
      };
    }
    return await response.json();
  },

  async getScripts(accessToken: string): Promise<Result<ScriptsWithObjections[]>> {
    const url = `${rootUrl}/api/v1/scripts`;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await fetch(url, requestOptions);
    if (response.status === 403) {
      throw {
        status: 403,
        statusText: 'Forbidden',
      };
    }
    return await response.json();
  },

  async postScripts(scripts: ScriptsWithObjections[], accessToken: string): Promise<void> {
    const url = `${rootUrl}/api/v1/scripts`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(scripts),
    };

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw {
        status: response.status,
        statusText: response.statusText,
      };
    }
  },
};
