import { useState } from 'react';
import { Button, notification, Row } from 'antd';
import { GetAccessToken } from '../../../../utils/auth-utils';
import { scopes } from '../../../../authConfig';
import { handleDownloadAll } from '../../../../utils/file-utils';
import { getGroupSlotData } from '../../../../services/admin-page-service';
import { useMsal } from '@azure/msal-react';
import dayjs from 'dayjs';

export const AnalyticsDownload = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { instance, inProgress, accounts } = useMsal();

  const downloadSlotsAndManagers = async () => {
    setIsLoading(true);
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);

    const startDateTime = dayjs().startOf('day').toISOString();
    const endDateTime = dayjs().add(3, 'week').endOf('day').toISOString();

    const totalOps = await getGroupSlotData(token!.accessToken, startDateTime, endDateTime);
    if (!totalOps.isSuccess) {
      notification.error({ message: 'Failed to download slots and managers', description: totalOps?.errors?.join(',') });
      setIsLoading(false);
      return;
    }
    if (totalOps.data.length === 0) {
      notification.error({ message: 'No data found for the selected time range' });
      setIsLoading(false);
      return;
    }

    handleDownloadAll(totalOps.data, `groupedslots_${dayjs(startDateTime).format('YYYY-MM-DD HH:mm')}_${dayjs(endDateTime).format('YYYY-MM-DD HH:mm')}`);
    setIsLoading(false);
  };

  return (
    <div>
      <Button className="clp-download-btn" onClick={downloadSlotsAndManagers} loading={isLoading}>
        Download All
      </Button>
    </div>
  );
};
