import { CustomerRule, CustomerRuleOutcome, RuleFor } from '../../../../dto/admin-rule-models';
import { useEffect, useState } from 'react';
import { Form, Input, Button, Tabs, Checkbox, notification, Badge } from 'antd';
import { SalesChannel } from '../../../../dto/model';
import { OutcomeTab } from './customer-rule-outcomes';
import { CalendarOutlined, FileDoneOutlined, LoginOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { CustomerRuleConditions } from './customer-rule-conditions';
import CustomerRulePreviewChanges from './customer-rule-preview-changes';

const CheckboxGroup = Checkbox.Group;

type CustomerRuleFormProps = {
  rule: CustomerRule;
  onSubmit: (rule: CustomerRule) => void;
  onCancel: () => void;
};

export const CustomerRuleForm = ({ rule, onSubmit, onCancel }: CustomerRuleFormProps) => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState(RuleFor.OpportunitySync);
  const [currentRule, setCurrentRule] = useState<CustomerRule>(rule);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setCurrentRule(rule);
  }, [rule]);

  const handleTabChange = (key: string) => {
    setActiveTab(key as RuleFor);
  };
  const options = [
    { label: 'Digital Sales', value: SalesChannel.DigitalSales },
    { label: 'CRM Sales', value: SalesChannel.CrmSales },
    { label: 'Revision Queue', value: SalesChannel.Revision },
    { label: 'Sales Call 2', value: SalesChannel.SalesCall2 },
  ];
  const onSalesChannelSelect = (value: SalesChannel[]) => {
    setCurrentRule({ ...currentRule, salesChannels: value });
  };

  const onFormSubmit = async () => {
    try {
      await form.validateFields();

      const groupedOutcomes = currentRule.outcomes.reduce((acc, outcome) => {
        if (!acc[outcome.ruleFor]) {
          acc[outcome.ruleFor] = [];
        }
        acc[outcome.ruleFor].push(outcome);
        return acc;
      }, {} as Record<RuleFor, CustomerRuleOutcome[]>);

      const ruleForKeys = Object.keys(RuleFor) as (keyof typeof RuleFor)[];

      for (const key of ruleForKeys) {
        const ruleFor = RuleFor[key];
        if (groupedOutcomes[ruleFor] && groupedOutcomes[ruleFor].length > 0) {
          if (!groupedOutcomes[ruleFor] || groupedOutcomes[ruleFor].length === 0) {
            notification.error({
              message: 'Validation Error',
              description: 'We need at least one outcome and one condition for' + ruleFor,
            });
            return;
          }
          let areMethodNamesValid = true;
          groupedOutcomes[ruleFor].forEach((element) => {
            if (element.methodNames.length === 0) {
              areMethodNamesValid = false;
            }
          });
          if (!areMethodNamesValid) {
            notification.error({
              message: 'Validation Error',
              description: 'Each outcome must have at least one method name for ' + ruleFor,
            });
            return;
          }
        }
      }

      onSubmit(currentRule);
    } catch (e) {
      console.error(e);
    }
  };
  const getOutcomeCount = (ruleFor: RuleFor) => {
    return currentRule.outcomes.filter((outcome) => outcome.ruleFor === ruleFor).length;
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFormSubmit}>
      <Form.Item label="Title" name={['title', rule.feId]} initialValue={rule.title} rules={[{ required: true, message: 'Please input the title!' }]}>
        <Input placeholder="Title for the customer rule" onChange={(e) => setCurrentRule({ ...currentRule, title: e.target.value })} value={currentRule?.title} />
      </Form.Item>

      <Form.Item label="Description" name={['description', rule.feId]} initialValue={rule.description} rules={[{ required: true, message: 'Please input the description!' }]}>
        <Input.TextArea placeholder="Description for your customer rule" onChange={(e) => setCurrentRule({ ...currentRule, description: e.target.value })} value={currentRule?.description} rows={2} />
      </Form.Item>
      <Form.Item
        label="Sales Channels"
        name={['salesChannels', rule.feId]}
        rules={[{ required: true, message: 'Please select a sales channel!' }]}
        initialValue={currentRule.salesChannels ? currentRule.salesChannels : []}
      >
        <CheckboxGroup options={options} value={currentRule.salesChannels ? currentRule.salesChannels : []} onChange={onSalesChannelSelect} />
      </Form.Item>

      <CustomerRuleConditions rule={currentRule} setRule={setCurrentRule} />

      <h4>Outcomes</h4>
      <Tabs tabPosition={'left'} defaultActiveKey={RuleFor.OpportunitySync} onChange={handleTabChange}>
        <Tabs.TabPane
          tab={
            <span style={{ marginRight: 10 }}>
              <LoginOutlined style={{ marginRight: 5 }} />
              Opportunity Sync
              <Badge count={getOutcomeCount(RuleFor.OpportunitySync)} offset={[10, 0]} style={{ backgroundColor: '#d9d9d9', color: 'black' }} />
            </span>
          }
          key={RuleFor.OpportunitySync}
        >
          {activeTab === RuleFor.OpportunitySync && <OutcomeTab rule={currentRule} setRule={setCurrentRule} tabPane={RuleFor.OpportunitySync} />}
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span>
              <CalendarOutlined style={{ marginRight: 5 }} />
              Calendar Display
              <Badge count={getOutcomeCount(RuleFor.CalendarDisplay)} offset={[10, 0]} style={{ backgroundColor: '#d9d9d9', color: 'black' }} />
            </span>
          }
          key={RuleFor.CalendarDisplay}
        >
          {activeTab === RuleFor.CalendarDisplay && <OutcomeTab rule={currentRule} setRule={setCurrentRule} tabPane={RuleFor.CalendarDisplay} />}
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span>
              <UserSwitchOutlined style={{ marginRight: 5 }} />
              Slot Booking
              <Badge count={getOutcomeCount(RuleFor.SlotBooking)} offset={[10, 0]} style={{ backgroundColor: '#d9d9d9', color: 'black' }} />
            </span>
          }
          key={RuleFor.SlotBooking}
        >
          {activeTab === RuleFor.SlotBooking && <OutcomeTab rule={currentRule} setRule={setCurrentRule} tabPane={RuleFor.SlotBooking} />}
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span>
              <FileDoneOutlined style={{ marginRight: 5 }} />
              Scripts
              <Badge count={getOutcomeCount(RuleFor.Scripts)} offset={[10, 0]} style={{ backgroundColor: '#d9d9d9', color: 'black' }} />
            </span>
          }
          key={RuleFor.Scripts}
        >
          {activeTab === RuleFor.Scripts && <OutcomeTab rule={currentRule} setRule={setCurrentRule} tabPane={RuleFor.Scripts} />}
        </Tabs.TabPane>
      </Tabs>

      <div className="bg-gray-50 p-4 rounded-md" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => setIsModalVisible(true)}>Preview Changes</Button>
        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
      </div>

      <CustomerRulePreviewChanges visible={isModalVisible} onClose={() => setIsModalVisible(false)} customerRule={currentRule} />
    </Form>
  );
};
