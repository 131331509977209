import React, { useState } from 'react';
import { Button, Card, Modal, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BaseCardModel, CommonEditComponentProps } from '../../dto/model';

const { confirm } = Modal;
const { Title } = Typography;

type CommonCardProps<T extends BaseCardModel> = {
  model: T;
  onSubmit: (form: T) => void;
  onDelete: (form: T) => void;
  EditComponent: React.ComponentType<CommonEditComponentProps<T>>;
};

export const CommonCard = <T extends BaseCardModel>({ model, onSubmit, onDelete, EditComponent }: CommonCardProps<T>) => {
  const [shouldEdit, setShouldEdit] = useState(false);

  const onSubmitEdit = (card: T) => {
    onSubmit(card);
    setShouldEdit(false);
  };

  const setEditDisplay = (status: boolean) => {
    setShouldEdit(status);
  };

  const deleteCard = () => {
    onDelete(model);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this?',
      icon: <DeleteOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteCard();
      },
    });
  };

  return (
    <Card style={{ marginBottom: '25px' }}>
      {shouldEdit ? (
        <div>
          <EditComponent model={model} onSubmit={onSubmit} onCancel={() => setEditDisplay(false)} />
        </div>
      ) : (
        <>
          <Title level={3}>{model.name}</Title>
          <p>{model.description}</p>

          <Button onClick={() => setEditDisplay(true)} icon={<EditOutlined />} style={{ marginRight: '10px' }}>
            Edit
          </Button>
          <Button onClick={showDeleteConfirm} icon={<DeleteOutlined />}>
            Delete
          </Button>
        </>
      )}
    </Card>
  );
};
