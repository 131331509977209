import './snooze-config-row.css';
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputNumber, Row, Select, Space } from "antd";
import { BookingConfiguration, timeFrameOptions, TimeFrames } from "../../../../dto/admin-config-models";


type SnoozeConfigRowProps = {
  remove: (feId: string) => void;
  config: BookingConfiguration;
  updateConfig: (config: BookingConfiguration) => void;
  isLocked: boolean;
};

const SnoozeConfigRow = ({ remove, config, updateConfig, isLocked }: SnoozeConfigRowProps) => {

  const addSnoozeRange = () => {
    const maxEndCount = config.snoozeRanges.reduce((max, range) => Math.max(max, range.endCount) + 1, 0);

    config.snoozeRanges.push({
      startCount: maxEndCount,
      endCount: maxEndCount + 1,
      snoozeDuration: 0,
      timeFrame: 'minutes'
    });
    updateConfig(config);
  }

  const removeSnoozeRange = (subRowIndex: number) => {
    config.snoozeRanges.splice(subRowIndex, 1);
    updateConfig(config);
  }
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    config.abTestingFragment = value;
    updateConfig(config);
  };

  const getTitle = () => {
    return config.createdBy === 'System'
      ? 'Default Configuration'
      : `Custom Configuration`;
  }
  return (
    <Card
      style={{
        opacity: isLocked ? 0.5 : 1,
        pointerEvents: isLocked ? 'none' : 'auto',
        cursor: isLocked ? 'not-allowed' : 'default'
      }}
      size="small"
      title={getTitle()}
      className='snooze-config-row'
      headStyle={{ backgroundColor: '#E6F4FF' }}
      key={config.feId}
      extra={
        (config.createdBy === 'System'
          ? null
          : <Space >
            <span style={{ fontWeight: 'bold' }}>AB Testing</span>
            <Input
              type='text'
              disabled={config.createdBy == 'System'}
              value={config.abTestingFragment}
              onInput={handleInputChange}
              style={{ width: 150 }}
            />
            <CloseOutlined
              className='close-icon'
              onClick={() => {
                remove(config.feId!);
              }}
            />
          </Space>
        )
      }
    >
      <Space >
        <Form.Item>
          <Form.List name={[config.feId!, 'list']}>
            {(subFields, subOpt) => (
              <div style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                {config.snoozeRanges.map((snooze, subRowIndex) => (
                  <Row gutter={16} key={subRowIndex} >
                    <Form.Item style={{ marginLeft: '10px' }} label={subRowIndex == 0 && <span style={{ fontWeight: 'bold' }}>Not Reached Counter</span>} name={[subRowIndex, 'name']}>
                      <InputNumber
                        style={{ marginRight: '10px', width: '180px' }}
                        inputMode="numeric"
                        required={true}
                        value={snooze.startCount}
                        onChange={(value) => { snooze.startCount = value!; updateConfig(config); }}
                        min={0}
                      />
                      <span style={{ marginRight: '10px' }}>
                        -
                      </span>
                      <InputNumber
                        style={{ marginRight: '10px', width: '180px' }}
                        inputMode="numeric"
                        required={true}
                        value={snooze.endCount}
                        onChange={(value) => { snooze.endCount = value!; updateConfig(config); }}
                        min={0}
                      />
                      <ArrowRightOutlined
                        style={{ marginRight: '10px' }}
                      />
                    </Form.Item>
                    <Form.Item label={subRowIndex == 0 && <span style={{ fontWeight: 'bold' }}>Snooze Duration</span>} name={[subRowIndex, 'name']}>
                      <InputNumber
                        style={{ marginRight: '10px', width: '180px' }}
                        inputMode="numeric"
                        required={true}
                        value={snooze.snoozeDuration}
                        onChange={(value) => { snooze.snoozeDuration = value!; updateConfig(config); }}
                        min={0}
                      />
                      <span style={{ marginRight: '10px' }}>
                        -
                      </span>
                      <Select style={{ marginRight: '8px', width: '180px' }}
                        value={TimeFrames[snooze.timeFrame as keyof typeof TimeFrames]}
                        onChange={(value) => { snooze.timeFrame = value!; updateConfig(config); }}
                        options={timeFrameOptions}
                      >
                      </Select>
                      <CloseOutlined
                        onClick={() => {
                          removeSnoozeRange(subRowIndex);
                        }}
                      />
                    </Form.Item>

                  </Row>
                ))}
                <Button type="dashed" onClick={addSnoozeRange} block>
                  + Add Snooze Range
                </Button>
              </div>
            )}
          </Form.List>
        </Form.Item>
      </Space>
    </Card>
  );
};

export default SnoozeConfigRow;