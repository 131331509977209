import { TrophyOutlined } from '@ant-design/icons';
import './ranking-card.css';
import { Card, Typography, Badge } from 'antd';
import { RatingDto } from '../../dto/reporting-models';
import { strings } from '../../lang';
const { Title, Text } = Typography;

type RankingCardProps = {
  title: string;
  rankData: RatingDto | null;
};

const RankingCard = ({ title, rankData }: RankingCardProps) => {
  const getBadgeColor = (index: number) => {
    switch (index) {
      case 0:
        return '#FFB302'; // Gold
      case 1:
        return '#706F6D'; // Silver
      case 2:
        return '#CD7F32'; // Bronze
      default:
        return '#ebeff5'; // Default color
    }
  };
  const getBadgeIcon = (index: number) => {
    if (index < 3) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TrophyOutlined style={{ color: getBadgeColor(index), fontSize: '20px' }} />
        </div>
      );
    }
    return (
      <div style={{ display: 'flex' }}>
        <Badge count={index + 1} style={{ backgroundColor: '#e8e8e8', color: 'black', fontWeight: '600' }} />
      </div>
    );
  };

  const getYourPositionBackgroundColor = (position: number) => {
    switch (position) {
      case 1:
        return '#FFB302'; // Gold
      case 2:
        return '#706F6D'; // Silver
      case 3:
        return '#CD7F32'; // Bronze
      default:
        return '#011557'; // Very dark blue
    }
  };

  const getColor = (position: number) => {
    return position === 1 ? 'black' : 'white';
  };

  return (
    <Card
      className="ranking-card"
      title={
        <Title level={5} style={{ marginLeft: '25px' }}>
          {title}
        </Title>
      }
    >
      <div className="callers-list">
        {rankData &&
          rankData.leaderboard &&
          rankData.leaderboard
            .slice() // Create a shallow copy of the data array to avoid mutating the original array
            .slice(0, 5) // Take only the first 5 elements
            .map((item, index) => (
              <div key={index} className="caller-item">
                <div style={{ marginLeft: '15px', display: 'flex' }}>
                  {getBadgeIcon(index)}
                  <Text strong style={{ marginLeft: '10px', marginRight: '20px', fontSize: '15px' }}>
                    {item.fullName}
                  </Text>
                </div>
                <Text style={{ marginRight: '20px' }}>{item.count}</Text>
              </div>
            ))}
        <div className="position-rank" style={{ backgroundColor: getYourPositionBackgroundColor(rankData?.currentUserPosition ?? 10), color: getColor(rankData?.currentUserPosition ?? 10) }}>
          <div style={{ marginLeft: '15px', display: 'flex' }}>
            <Badge count={rankData?.currentUserPosition} overflowCount={50} style={{ backgroundColor: '#ffffff', color: 'black', fontWeight: '600', marginRight: '20px' }} />
            <div>{strings.reportingYourPosition}</div>
          </div>
          <div style={{ marginRight: '20px' }}>{rankData?.currentUserCriteriaCount}</div>
        </div>
      </div>
    </Card>
  );
};

export default RankingCard;
