//import { faker } from '@faker-js/faker';
import { UserCustomizableUI } from '../dto/model';
import { LeaderboardDto, UserDataDto, RatingDto, UserAction, ActionDto, Achievement, TeamModel, TeamMemberModel, RatingsResponse, Metrics, UserModel } from '../dto/reporting-models';

const reportBaseUrl = (process as any).env.REACT_APP_REPORT_API_ROOT_URL;

export const reportingService = {
  async ModifyCustomizableUIAsync(accessToken: string, userCustomizableUI: UserCustomizableUI): Promise<Response> {
    const url = `${reportBaseUrl}/api/v1/profiles/self`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify(userCustomizableUI),
    });

    return response;
  },

  async getGamifiedUsersCountAsync(accessToken: string): Promise<number> {
    const url = `${reportBaseUrl}/api/v1/reports/users/count`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to get gamified users count: ${response.statusText}`);
    }

    const result = await response.text();
    return +result;
  },

  async downloadReport(accessToken: string): Promise<void> {
    try {
      const response = await fetch(`${reportBaseUrl}/api/v1/reports/users/progress/download`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to download: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = 'Gamification User Progress Report.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading report:', error);
    }
  },

  async getAchievementsRarity(accessToken: string): Promise<Record<number, number>> {
    const url = `${reportBaseUrl}/api/v1/achievements/rarity`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    const result = await response.json();
    return result;
  },

  async getAchievementsAsync(accessToken: string): Promise<Achievement[]> {
    const url = `${reportBaseUrl}/api/v1/achievements`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    const result = await response.json();
    return result;
  },

  async deleteAchievementAsync(accessToken: string, achievementId: number): Promise<Response> {
    const url = `${reportBaseUrl}/api/v1/admin/achievements/${achievementId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    return response;
  },

  async postAchievementsAsync(accessToken: string, achievement: Achievement[]): Promise<Response> {
    const url = `${reportBaseUrl}/api/v1/admin/achievements`;
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify(achievement),
    });

    return response;
  },

  async modifyActionsAsync(accessToken: string, actions: ActionDto[]): Promise<Response> {
    const url = `${reportBaseUrl}/api/v1/admin/actions`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify(actions),
    });

    return response;
  },

  async getActionsAsync(accessToken: string): Promise<ActionDto[]> {
    const url = `${reportBaseUrl}/api/v1/actions`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    const result = await response.json();
    return result;
  },

  async getLastActionsAsync(accessToken: string): Promise<UserDataDto> {
    const url = `${reportBaseUrl}/api/v1/profiles/self/actions`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    const result = await response.json();
    return result;
  },

  async getTopByExperienceAsync(accessToken: string): Promise<RatingDto> {
    const url = `${reportBaseUrl}/api/v1/leaderboards/experience`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getUserDataAsync(trimmed: boolean, accessToken: string): Promise<UserDataDto> {
    const url = trimmed ? `${reportBaseUrl}/api/v1/profile?isTrimmed=true` : `${reportBaseUrl}/api/v1/profile`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    if (response.status === 404) {
      throw new Error('User data not found');
    }
    const result = await response.json();
    return result;
  },

  async getLeaderboardDataAsync(accessToken: string): Promise<LeaderboardDto> {
    const url = `${reportBaseUrl}/api/v1/leaderboard`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getTeamsAsync(accessToken: string): Promise<TeamModel[]> {
    const url = `${reportBaseUrl}/api/v1/teams`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getAgentsByTeamIdAsync(teamId: number, accessToken: string): Promise<TeamMemberModel[]> {
    const url = `${reportBaseUrl}/api/v1/teams/${teamId}/members`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getRatingAsync(accessToken: string, isTeamRating: boolean): Promise<RatingsResponse> {
    const url = `${reportBaseUrl}/api/v1/metrics/rating?teamRating=${isTeamRating}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getTeamRatingAsync(accessToken: string, teamId: number): Promise<RatingsResponse> {
    const url = `${reportBaseUrl}/api/v1/teams/${teamId}/ratings`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getUserMetricsAsync(accessToken: string, azureId: string): Promise<Metrics> {
    const url = `${reportBaseUrl}/api/v1/metrics/${azureId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getSelfMetricsAsync(accessToken: string): Promise<Metrics> {
    const url = `${reportBaseUrl}/api/v1/metrics/self`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getTeamMetricsAsync(accessToken: string, teamId: number): Promise<Metrics> {
    const url = `${reportBaseUrl}/api/v1/teams/${teamId}/metrics`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getCurrentTeamMetricsAsync(accessToken: string): Promise<Metrics> {
    const url = `${reportBaseUrl}/api/v1/teams/self/metrics`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getUsersAsync(accessToken: string): Promise<UserModel[]> {
    const url = `${reportBaseUrl}/api/v1/admin/users`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async postTeamAsync(accessToken: string, team: TeamModel): Promise<TeamModel> {
    const url = `${reportBaseUrl}/api/v1/teams`;
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify(team),
    });
    return await response.json();
  },

  async modifyMembersAsync(accessToken: string, teamId: number, members: string[]): Promise<TeamModel> {
    const url = `${reportBaseUrl}/api/v1/teams/${teamId}/members`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify(members),
    });
    return await response.json();
  },

  async deleteTeamAsync(accessToken: string, teamId: number): Promise<Response> {
    const url = `${reportBaseUrl}/api/v1/teams/${teamId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    return response;
  },

  async updateTeamAsync(accessToken: string, team: TeamModel): Promise<void> {
    const url = `${reportBaseUrl}/api/v1/teams`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify(team),
    });
  },
};
