import { useEffect, useState } from "react"
import { bookingPageService } from "../../../../services/booking-page-service"
import { GetAccessToken } from "../../../../utils/auth-utils";
import { useMsal } from "@azure/msal-react";
import { scopes } from "../../../../authConfig";
import { EditContact } from "../../../../dto/model";
import { Button, Checkbox, message, notification, Table, Tooltip } from "antd";
import { CopyOutlined, LoadingOutlined, ReloadOutlined } from "@ant-design/icons";

export const EditContactContainer = () => {
  const { instance, inProgress } = useMsal();
  const [contactsToEdit, setContactsToEdit] = useState<EditContact[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [instance, inProgress]);

  const fetchData = async () => {
    setLoading(true);

    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const contactsResult = await bookingPageService.getContactsToEdit(token.accessToken, false);

    if (!contactsResult.isSuccess) {
      notification.error({
        message: "Error",
        description: "Could not download the contacts to edit",
      });
      return;
    }
    setContactsToEdit(contactsResult.data);
    setLoading(false);
  };

  const handleCheckboxChange = (record: EditContact, e: any) => {
    const updatedContacts = contactsToEdit.map((contact) => {
      if (contact.id === record.id) {
        return {
          ...contact,
          isCompleted: e.target.checked,
          lastModifiedUtc: new Date().toISOString()
        };
      }
      return contact;
    });
    setContactsToEdit(updatedContacts);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    message.success(`"${text}" copied to clipboard.`);
  };

  const saveChanges = async () => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const result = await bookingPageService.postEditContacts(contactsToEdit, token.accessToken);
    if (!result.isSuccess) {
      notification.error({
        message: "Error",
        description: "Could not save the changes",
      });
      return;
    }

    await fetchData();

    notification.success({
      message: "Success",
      description: "Changes saved successfully",
    });

  };

  const columns = [
    {
      title: "Modified At",
      dataIndex: "modifiedAtUtc",
      key: "modifiedAtUtc",
      render: (date: Date) => new Date(date).toLocaleString(),
    },
    {
      title: "Salutation",
      key: "salutation",
      render: (_: any, record: EditContact) => {
        if (!record.oldSalutation || !record.salutation) return null;
        return (
          <>
            <em>{record.oldSalutation}</em> {'->'} <strong>{record.salutation}</strong>
            <Tooltip title="Copy to clipboard">
              <Button
                type="text"
                icon={<CopyOutlined />}
                onClick={() => handleCopy(record.salutation ?? '')}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_: any, record: EditContact) => {
        if (!record.oldFirstName || !record.firstName) return null;
        return (
          <>
            <em>{record.oldFirstName}</em> {'->'} <strong>{record.firstName}</strong>
            <Tooltip title="Copy to clipboard">
              <Button
                type="text"
                icon={<CopyOutlined />}
                onClick={() => handleCopy(record.firstName ?? '')}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (_: any, record: EditContact) => {
        if (!record.oldLastName || !record.lastName) return null;
        return (
          <>
            <em>{record.oldLastName}</em> {'->'} <strong>{record.lastName}</strong>
            <Tooltip title="Copy to clipboard">
              <Button
                type="text"
                icon={<CopyOutlined />}
                onClick={() => handleCopy(record.lastName ?? '')}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Edit Salutation/Name",
      dataIndex: "contactLink",
      key: "contactLink",
      render: (url: string) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          Contact
        </a>
      ),
    },
    {
      title: "Street",
      key: "street",
      render: (_: any, record: EditContact) => {
        if (!record.oldStreet || !record.street) return null;
        return (
          <>
            <em>{record.oldStreet}</em> {'->'} <strong>{record.street}</strong>
            <Tooltip title="Copy to clipboard">
              <Button
                type="text"
                icon={<CopyOutlined />}
                onClick={() => handleCopy(record.street ?? '')}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Zip Code",
      key: "zipCode",
      render: (_: any, record: EditContact) => {
        if (!record.oldZipCode || !record.zipCode) return null;
        return (
          <>
            <em>{record.oldZipCode}</em> {'->'} <strong>{record.zipCode}</strong>
            <Tooltip title="Copy to clipboard">
              <Button
                type="text"
                icon={<CopyOutlined />}
                onClick={() => handleCopy(record.zipCode ?? '')}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "City",
      key: "city",
      render: (_: any, record: EditContact) => {
        if (!record.oldCity || !record.city) return null;
        return (
          <>
            <em>{record.oldCity}</em> {'->'} <strong>{record.city}</strong>
            <Tooltip title="Copy to clipboard">
              <Button
                type="text"
                icon={<CopyOutlined />}
                onClick={() => handleCopy(record.city ?? '')}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
          </>
        );
      },
    },

    {
      title: "Edit Installation Address",
      dataIndex: "opportunityLink",
      key: "opportunityLink",
      render: (url: string) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          Opportunity
        </a>
      ),
    },
    {
      title: "Completed",
      dataIndex: "isCompleted",
      key: "isCompleted",
      render: (value: boolean, record: EditContact) => (
        <Checkbox checked={value} onChange={(e) => handleCheckboxChange(record, e)} />
      ),
    },
  ];

  return (
    <div
      className="container flex-admin-vertical-container"
      style={{ width: '100%', overflowX: "auto" }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
        <Button
          type="default"
          onClick={fetchData}
          icon={loading ? <LoadingOutlined /> : <ReloadOutlined />}
          loading={loading}
        >
          Refresh
        </Button>
      </div>
      <Table
        dataSource={contactsToEdit}
        columns={columns}
        rowKey="id"
        pagination={false}
        style={{ width: "100%" }}
        scroll={{ x: true }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
        <Button type="primary" onClick={saveChanges}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};