import './customer-lost-modal.css';
import { Button, Checkbox, Modal, Select, Tooltip, Typography } from 'antd';
import { strings } from '../../../lang';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { CustomerDeleteData, SalesChannel } from '../../../dto/model';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { DoNotCloseTag } from '../../common/donotclose-tag';

export interface CustomerLostInfo {
  reasons: string[];
  description: string;
  shareContactData: boolean | null;
  isDataDeletionRequested: boolean;
}

export interface CustomerLostBodyProps {
  open: boolean;
  onOk: (customerLostInfo: CustomerLostInfo) => Promise<void>;
  onCancel: () => void;
  inboundCenter?: boolean | undefined;
  salesChannel: SalesChannel;
  customerDeleteData: CustomerDeleteData
}

export const CustomerLostModal = ({ open, onOk, onCancel, inboundCenter, salesChannel, customerDeleteData }: CustomerLostBodyProps) => {
  const [customerLostOptions, setCustomerLostOptions] = useState<string[]>([]);
  const [shareContactData, setShareCotnactData] = useState<boolean | null>(null);
  const [description, setDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const defaultErrorState = {
    tooManySelected: false,
    noSelected: false,
    noInput: false,
    tooMuchInput: false,
    noShareOptionSelected: false,
  };
  const [error, setError] = useState(defaultErrorState);
  const isRevision = salesChannel === SalesChannel.Revision;
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isDataDeletionRequested, setIsDataDeletionRequested] = useState<boolean>(false);

  const onOkPressed = async () => {
    if (customerLostOptions?.length > 3) {
      setError({ ...defaultErrorState, tooManySelected: true });
      return;
    }
    if (customerLostOptions?.length < 1) {
      setError({ ...defaultErrorState, noSelected: true });
      return;
    }

    if (description?.trim()?.length > 255) {
      setError({ ...defaultErrorState, tooMuchInput: true });
      return;
    }

    if (isRevision && !shareContactData && customerLostOptions.some(option => listOfOptionsToShareContactData.includes(option))) {
      setError({ ...defaultErrorState, noShareOptionSelected: true });
      return;
    }

    try {
      setIsLoading(true);
      await onOk({
        reasons: customerLostOptions,
        description,
        shareContactData: shareContactData,
        isDataDeletionRequested: isDataDeletionRequested
      });
      cleanState();
    } catch (e) {
      alert(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelPressed = () => {
    cleanState();
    onCancel();
  };

  const cleanState = () => {
    setCustomerLostOptions([]);
    setShareCotnactData(null);
    setDescription('');
    setError({
      tooManySelected: false,
      noSelected: false,
      noInput: false,
      tooMuchInput: false,
      noShareOptionSelected: false,
    });
    setIsDataDeletionRequested(false);
    setIsEmailSent(false);
  };


  const onDataDeletionRequested = (customerDeleteData: CustomerDeleteData) => {
    if (customerLostOptions?.length < 1) {
      setError({ ...defaultErrorState, noSelected: true });
      return;
    }
    setIsDataDeletionRequested(true);
    const recipient = 'datenschutz@enpal.de';
    const subject = `Datenlöschung ${customerDeleteData.customerFullName}`;
    const body = `Sehr geehrtes Datenschutz-Team,

Folgender Kunde hat soeben im Booking eine Datenlöschung beantragt.

Name: ${customerDeleteData.customerFullName}
Link: ${customerDeleteData.contactUrl} 

Mit freundlichen Grüßen

${customerDeleteData.bookingAgentFullName}`;

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    const a = document.createElement('a');
    a.href = mailtoLink;
    a.target = '_blank';
    a.click();
  };

  //TODO: Uncomment when we want to go live with the feature.
  const listOfOptionsToShareContactData = [
    'Reason that will never exist',
    // 'Owns PV system',
    // 'Roof over 3 months finished',
    // 'Other (please specify in comment)',
    // 'Technically not feasible',
    // 'No residence',
    // 'No wifi/laptop',
    // 'Apartment building (Mehrfamilienhaus)'
  ];

  const customerLostButtons = () => {

    return (
      <>
        {isDataDeletionRequested &&
          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', marginBottom: '20px', marginTop: '10px', width: '100%' }}>
            <Checkbox onChange={() => setIsEmailSent(!isEmailSent)}>{strings.customerLostConfirmEmailSent}</Checkbox>
          </div>}
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
          <Button key="cancel" onClick={onCancelPressed} disabled={isLoading}>
            {strings.cancel}
          </Button>
          <Tooltip title={strings.customerLostDataDeletionButtonToolTip}>
            <Button key="dataDeletion" onClick={() => onDataDeletionRequested(customerDeleteData)} disabled={isLoading}>
              {strings.customerLostDataDeletion}
            </Button>
          </Tooltip>
          <Tooltip title={isDataDeletionRequested && !isEmailSent ? strings.customerLostDataDeletionProceedToolTip : ""}>
            <Button
              key="ok"
              type="primary"
              onClick={onOkPressed}
              disabled={isLoading || (isDataDeletionRequested && !isEmailSent)}
              loading={isLoading}
            >
              {inboundCenter ? strings.backToInboundCenter : strings.saveAndOpenNextCase}
            </Button>
          </Tooltip>
        </div>
      </>
    )
  };

  return (
    <Modal
      title={isRevision ? strings.looseCustomerButton : strings.modalTitleCustomerLost}
      centered
      destroyOnClose={true}
      closable={false}
      maskClosable={false}
      keyboard={false}
      open={open}
      width={600}
      cancelText={strings.cancel}
      onCancel={() => onCancelPressed()}
      cancelButtonProps={{ disabled: isLoading }}
      footer={customerLostButtons()}
    >
      <span>{isRevision ? strings.modalTextCustomerLostRevision : strings.modalTextCustomerLost}</span>
      <br />
      {error.noSelected && <div style={{ color: 'red' }}>{strings.customerLostAtLeastOneOptionError}</div>}
      {error.tooManySelected && <div style={{ color: 'red' }}>{strings.customerLostUpTo3OptionsError}</div>}
      {isRevision && customerLostOptions.some(option => listOfOptionsToShareContactData.includes(option)) &&
        <Typography.Text style={{ display: 'inline-block', marginTop: '5px', fontWeight: 'bold' }}>
          {strings.customerLostShareDataLabel}
          <Tooltip title={strings.customerLostShareDataTooltip}>
            <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
          </Tooltip>
        </Typography.Text>}
      <Select
        className="cust-lost-select"
        mode="multiple"
        placeholder={strings.selectAtLeastOneReason}
        options={strings.customerLostOptions}
        onChange={setCustomerLostOptions}
        maxTagCount={3}
        value={customerLostOptions}
        status={error.noSelected === true || error.tooManySelected ? 'error' : ''}
      />

      {error.noInput && <div style={{ color: 'red' }}>{strings.customerLostNoCharactersError}</div>}
      {error.tooMuchInput && <div style={{ color: 'red' }}>{strings.customerLostTooManyCharactersError}</div>}
      <TextArea
        maxLength={255}
        onChange={(e: any) => setDescription(e.target.value)}
        rows={3}
        value={description}
        placeholder={strings.customerLostPlaceholder}
        status={error.noInput || error.tooMuchInput === true ? 'error' : ''}
      />

      {isRevision && customerLostOptions.some(option => listOfOptionsToShareContactData.includes(option)) &&
        <div style={{ marginTop: '20px' }}>
          {error.noShareOptionSelected && <div style={{ color: 'red', }}>{strings.customerLostShareDataNoOptionError}</div>}
          <Select
            style={{ width: '470px', marginBottom: '15px', }}
            placeholder={strings.customerLostShareDataPlaceholder}
            options={strings.customerLostShareDataEntries}
            onChange={setShareCotnactData}
            maxTagCount={1}
            value={shareContactData}
            status={error.noShareOptionSelected ? 'error' : ''}
            title={strings.customerLostShareDataPlaceholder}
          />
        </div>}
      {isLoading && <DoNotCloseTag />}
    </Modal>
  );
};
