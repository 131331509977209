import { useEffect, useState } from 'react';
import { strings } from '../../lang';
import { Header } from '../header/header';
import './inbound-center.css';
import { message } from 'antd';
import { OpportunitySearchResult } from '../../dto/model';
import { useMsal } from '@azure/msal-react';
import { GetAccessToken } from '../../utils/auth-utils';
import { scopes } from '../../authConfig';
import { bookingPageService } from '../../services/booking-page-service';
import Search from 'antd/es/input/Search';
import { useLocation } from 'react-router-dom';
import InboundOpportunity from './inbound-opportunity';

export interface InboundCenterProps {
  searchInput?: string | undefined | null;
}

export const InboundCenter = ({ searchInput }: InboundCenterProps) => {
  const { instance, inProgress } = useMsal();
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<OpportunitySearchResult[] | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string | undefined | null>(searchInput);
  const location = useLocation();

  const [isMessageShown, setIsMessageShown] = useState(false);
  const isSuccess = location?.state?.isSuccess;

  useEffect(() => {
    if (!isMessageShown && isSuccess !== undefined) {
      if (isSuccess === true) {
        message.success(strings.inboundCallBack.success);
      } else {
        message.error(strings.inboundCallBack.failure);
      }
      setIsMessageShown(true);
    }
  }, [isSuccess, isMessageShown]);

  useEffect(() => {
    if (searchInput) {
      onSearch(searchInput);
    }
  }, [searchInput]);

  const isEmail = (input: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const isPhoneNumber = (input: string): boolean => {
    const phoneRegex = /^(\+\d{1,3})?(\s?\d{3,})*$/;
    return phoneRegex.test(input);
  };

  const isZipCode = (input: string): boolean => {
    const zipCodeRegex = /^\d{5}$/;
    return zipCodeRegex.test(input);
  };

  const isId = (input: string): boolean => {
    const phoneRegex = /^[a-zA-Z0-9]{15}([a-zA-Z0-9]{3})?$/;
    return phoneRegex.test(input);
  };

  const createSearchQuery = (input: string): string => {
    input = input.trim();
    if (isPhoneNumber(input)) {
      return `phone=${input.replace(/\s/g, '')}`;
    } else if (/\s/.test(input)) {
      if (isZipCode(input.split(' ')[0])) {
        return `zip=${input.split(' ')[0]}&city=${input.split(' ').slice(1).join(' ')}`;
      } else {
        return `name=${input.split(' ')[0]}&lastName=${input.split(' ').slice(1).join(' ')}`;
      }
    } else {
      if (isEmail(input)) {
        return `email=${input}`;
      } else if (isId(input)) {
        return `id=${input}`;
      } else {
        return `lastName=${input}`;
      }
    }
  };

  const changeLoadingStates = (state: boolean, index?: number | undefined) => {
    if (!index) {
      setIsSearchLoading(state);
    }
  };

  const onSearch = async (value: string) => {
    if (value !== '') {
      changeLoadingStates(true);
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const localSearchResult = await bookingPageService.searchForOpportunities(token.accessToken, createSearchQuery(value));
      setSearchResult(localSearchResult);
      changeLoadingStates(false);
    }
  };

  return (
    <>
      <Header
        centerComponents={
          <Search
            style={{ width: 608 }}
            value={searchInputValue ?? undefined}
            onChangeCapture={(e) => {
              setSearchInputValue(e.currentTarget.value);
            }}
            onSearch={onSearch}
            enterButton
            disabled={isDisabled}
            loading={isSearchLoading}
            allowClear
            placeholder={strings.searchPlaceholder}
          />
        }
      />
      {searchResult && (
        <h3 style={{ marginLeft: 60 }}>
          {searchResult.length} Search Result
          {`${searchResult.length >= 2 ? 's' : ''}`}
          {searchResult.length === 0 && '. No results found. Use 3rd party search.'}
        </h3>
      )}

      {searchResult && searchResult.map((value, index) => <InboundOpportunity opportunityResult={value} isDisabled={isDisabled} setIsDisabled={setIsDisabled} />)}
    </>
  );
};
