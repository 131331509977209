import { useMsal } from '@azure/msal-react';
import { scopes } from '../../../../authConfig';
import { GetAccessToken } from '../../../../utils/auth-utils';
import { useEffect, useState } from 'react';
import { Achievement, ActionDto } from '../../../../dto/reporting-models';
import { Button, message, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { PlusOutlined } from '@ant-design/icons';
import { AchievementCard } from './achievement-card';
import { AchievementForm } from './achievement-form';
import { reportingService } from '../../../../services/reporting-service';

export const AdminAchievements = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [displayAchievementForm, shouldDisplayAchievementForm] = useState<boolean>(false);
  const [displayAddButton, shouldDisplayAddButton] = useState<boolean>(true);
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [actionsInfo, setActions] = useState<ActionDto[]>([]);

  useEffect(() => {
    const fetchActionsAsync = async () => {
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      try {
        const actionsResponse = await reportingService.getActionsAsync(token.accessToken);
        setActions(actionsResponse);
      } catch (error) {}
    };

    fetchActionsAsync();
    fetchAchievementsAsync();
  }, []);

  const fetchAchievementsAsync = async () => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    try {
      const achievementsResponse = await reportingService.getAchievementsAsync(token.accessToken);
      setAchievements(achievementsResponse);
    } catch (error) {}
  };

  const updateAchievementsAsync = async (achs: Achievement[]) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    try {
      await reportingService.postAchievementsAsync(token.accessToken, achs);
    } catch (error) {}
  };

  const addAchievement = () => {
    const newAchievement = {
      id: 0,
      name: '',
      description: '',
      points: 0,
      target: 0,
      actions: [],
      days: undefined,
      fromHour: undefined,
      toHour: undefined,
      icon: undefined,
      dueDate: undefined,
      isAccumulative: false,
    } as Achievement;
    setAchievements([...achievements, newAchievement]);
    shouldDisplayAchievementForm(true);
    shouldDisplayAddButton(false);
  };

  const onCancel = () => {
    const newAchievements = achievements.filter((r) => r.id !== 0);
    shouldDisplayAchievementForm(false);
    shouldDisplayAddButton(true);
    setAchievements(newAchievements);
  };

  const onSubmit = async (achievement: Achievement) => {
    const newAchievements = achievements.map((r) => {
      if (r.id === achievement.id) {
        return achievement;
      }
      return r;
    });

    await updateAchievementsAsync(newAchievements);
    await fetchAchievementsAsync();

    shouldDisplayAchievementForm(false);
    shouldDisplayAddButton(true);
  };

  const deleteAchievementAsync = async (id: number) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    try {
      const response = await reportingService.deleteAchievementAsync(token.accessToken, id);
      if (response.status === 200) {
        const newAchievements = achievements.filter((r) => r.id !== id);
        setAchievements(newAchievements);
      } else {
        message.error('Failed to delete achievement');
      }
    } catch (error) {}
  };

  return (
    <div className="container flex-admin-vertical-container" style={{ width: 900 }}>
      <Row justify="space-between" align="bottom" style={{ marginBottom: '20px' }}>
        <Title level={4} style={{ marginBottom: 0 }}>
          Gamification Achievements
        </Title>
        <Button type="primary" disabled={!displayAddButton} onClick={addAchievement} icon={<PlusOutlined />}>
          Add Achievement
        </Button>
      </Row>
      {displayAchievementForm && <AchievementForm achievement={achievements.find((x) => x.id === 0)!} actions={actionsInfo} onSubmit={onSubmit} cancel={onCancel} />}
      {achievements
        .filter((ach) => ach.id !== 0)
        .map((ach) => (
          <AchievementCard achievement={ach} actionsInfo={actionsInfo} shouldDisplayAddButton={shouldDisplayAddButton} onSubmit={onSubmit} deleteAchievement={deleteAchievementAsync} />
        ))}
    </div>
  );
};
