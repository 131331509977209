import { useEffect, useState } from 'react';
import { Select, Button, Form, Typography, Space, Card, Tabs, Spin, Row, Col, message, notification } from 'antd';
import { useMsal } from '@azure/msal-react';
import TabPane from 'antd/es/tabs/TabPane';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import { BookingConfiguration, BookingConfigurationStatus, bookingStatusOptions, salesChannelOptions } from '../../../../dto/admin-config-models';
import { SalesChannel } from '../../../../dto/model';
import { GetAccessToken } from '../../../../utils/auth-utils';
import { scopes } from '../../../../authConfig';
import { getConfigurations, postConfigurations } from '../../../../services/admin-page-service';
import SnoozeConfigRow from './snooze-config-row';

const { Title, Text } = Typography;
dayjs.extend(relativeTime);

const AdminSnoozeConfig = () => {
  const [form] = Form.useForm();
  const { instance, inProgress, accounts } = useMsal();
  const [configurations, setConfigurations] = useState<BookingConfiguration[] | null>(null);
  const [originalConfigurations, setOriginalConfigurations] = useState<BookingConfiguration[] | null>(null);
  const [salesChannel, setSalesChannel] = useState<SalesChannel>(SalesChannel.DigitalSales);
  const [activeTab, setActiveTab] = useState<string>(BookingConfigurationStatus.FirstTimeScheduling);
  const [isLocked, setIsLocked] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
        const data = await getConfigurations(token.accessToken, salesChannel);
        data?.map((x) => (x.feId = crypto.randomUUID()));
        setConfigurations(data!);
        setOriginalConfigurations(deepCopy(data));
      } catch (error) {
        alert(`Error fetching configurations: ${error}`);
      }
    };
    fetchConfigurations();
    setIsLocked(true);
  }, [salesChannel, refresh]);

  const deepCopy = (obj: any) => {
    return !obj
      ? null
      : JSON.parse(JSON.stringify(obj));
  };

  const updateConfig = (updatedConfig: BookingConfiguration) => {
    const updatedConfigurations = configurations!.map((item) => (updatedConfig.feId === item.feId ? updatedConfig : item));
    setConfigurations(updatedConfigurations);
  };

  const addNewConfiguration = () => {
    const filteredConfig = configurations?.filter((x) => x.salesChannel === salesChannel && x.bookingStatus === activeTab)[0]?.snoozeRanges;

    const newConfig: BookingConfiguration = {
      createdDate: new Date().toISOString(),
      feId: crypto.randomUUID(),
      lastModifiedDate: new Date().toISOString(),
      createdBy: accounts[0].username,
      salesChannel: salesChannel,
      bookingStatus: activeTab as BookingConfigurationStatus,
      abTestingFragment: '',
      snoozeRanges: filteredConfig ? filteredConfig!.map((x) => ({ ...x })) : [],
      shouldEnableTopBookers: false,
      topBookers: ''
    };
    setConfigurations([...configurations!, newConfig]);
  };

  const removeConfig = (feId: string) => {
    const config = configurations!.find((x) => x.feId === feId);
    if (!config) {
      return;
    }
    config.isDeleted = true;
    const updatedConfigurations = configurations!.map((item) => (feId === item.feId ? config : item));
    setConfigurations(updatedConfigurations);
  };

  const saveChanges = async () => {
    try {
      setIsLoading(true);
      const token = await GetAccessToken(
        instance,
        inProgress,
        scopes.salesAppointmentApi
      );

      const postResult = await postConfigurations(token.accessToken, configurations!);
      if (!postResult.isSuccess) {
        notification.error({
          message: 'Failed to save configuration',
          description: postResult.errors!.join('\n'),
        });

      }
      else {
        notification.success({
          message: 'Configuration saved successfully',
          description: `Number of updated configs: ${postResult.data}`,
        });
        setRefresh(!refresh);
        setIsLoading(false);
        setIsLocked(true);
      }
    } catch (error) {
      alert('Error saving configurations:');
    }
    finally {
      setIsLoading(false);
    }
  }

  const resetOnCancel = () => {
    setConfigurations(originalConfigurations);
    setIsLocked(true);
  }

  const latestConfiguration = configurations
    ?.slice()
    ?.sort((a, b) => new Date(b.lastModifiedDate).getTime() - new Date(a.lastModifiedDate).getTime())[0];

  return (
    <div style={{ padding: '20px', margin: '0 auto' }}>
      <Row align="top" style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '15px' }}>Sales Channel</span>
          <Select
            style={{
              width: '200px',
              opacity: !isLocked ? 0.5 : 1,
              pointerEvents: !isLocked ? 'none' : 'auto',
              cursor: !isLocked ? 'not-allowed !important' : 'default'
            }}
            value={salesChannel}
            onChange={(value: SalesChannel) => setSalesChannel(value)}
            options={salesChannelOptions}
          />
        </div>
        {isLocked && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text type="secondary">
              Last edited {dayjs(latestConfiguration?.lastModifiedDate).fromNow()}
            </Text>
            <Button style={{ marginLeft: '15px' }} color="default" onClick={() => setIsLocked(false)}>
              Edit
            </Button>
          </div>
        )}
      </Row>


      <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '900px' }}>
        <Space direction="vertical">
          <div>
            <Text style={{ display: 'block', marginTop: '5px', marginBottom: '10px', fontWeight: '600' }}>
              Please specify how long a customer should be snoozed after not being reached for the nth time.
            </Text>
            <Text type="secondary" style={{ display: 'block', marginTop: '5px', marginBottom: '10px' }}>
              Note: customers with a not-reached-counter outside this snooze configuration will not be called; e.g. if the highest not-reached-counter in this configuration is 35, then a customer with not-reached-counter 36 will not be called.
            </Text>
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
              {bookingStatusOptions.map((option) => (
                <TabPane tab={option.label} key={option.value.toString()} />
              ))}
            </Tabs>
          </div>
          {configurations
            ? <Form form={form} layout="vertical">
              <Form.List name="items">
                {(fields) => (
                  <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                    {configurations
                      && configurations.filter(x => x.bookingStatus === activeTab && !x.isDeleted).map((config, index) => (
                        <SnoozeConfigRow
                          key={index}
                          isLocked={isLocked}
                          remove={removeConfig}
                          config={config}
                          updateConfig={updateConfig} />
                      ))}
                  </div>
                )}

              </Form.List>
              <Button
                style={{
                  opacity: isLocked ? 0.5 : 1,
                  pointerEvents: isLocked ? 'none' : 'auto',
                  cursor: isLocked ? 'not-allowed' : 'default'
                }}
                onClick={addNewConfiguration}>
                + Add New Configuration
              </Button>
            </Form>
            : <Spin tip="Loading" size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          }

        </Space>
      </Card>
      {!isLocked &&
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button color="default" disabled={isLoading} onClick={resetOnCancel}>
            Cancel
          </Button>
          <Button type="primary" style={{ marginLeft: '10px' }} onClick={saveChanges} loading={isLoading}>
            Save Changes
          </Button>
        </div>}
    </div >
  );
};

export default AdminSnoozeConfig;
