import React, { useEffect, useState } from 'react';
import { Switch, Input, Form, Button, message, notification } from 'antd';
import { SalesChannel } from '../../../../dto/model';
import { GetAccessToken } from '../../../../utils/auth-utils';
import { scopes } from '../../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { getConfigurations, postConfigurations } from '../../../../services/admin-page-service';
import { BookingConfiguration, BookingConfigurationStatus } from '../../../../dto/admin-config-models';
import { set } from 'date-fns';

const AdminTopBookers = () => {
  const [configuration, setConfiguration] = useState<BookingConfiguration | null>(null);
  const [salesChannel, setSalesChannel] = useState<SalesChannel>(SalesChannel.DigitalSales);
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
        const data = await getConfigurations(token.accessToken, salesChannel);
        data?.map((x) => (x.feId = crypto.randomUUID()));
        setConfiguration(data!
          .filter((x) => x.salesChannel == salesChannel && x.bookingStatus == BookingConfigurationStatus.FirstTimeScheduling)
        [0]);
      } catch (error) {
        alert(`Error fetching configurations: ${error}`);
      }
    };
    fetchConfigurations();
  }, []);

  const handleToggleChange = (checked: boolean) => {
    if (configuration == null) {
      return;
    }
    configuration!.shouldEnableTopBookers = checked;
    setConfiguration({ ...configuration! });
  };

  const updateBookers = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const bookers = e.target.value.split('\n').map(booker => booker.trim().replace(/,/g, '')).join(',');
    setConfiguration({ ...configuration!, topBookers: bookers });
  };

  const handleSubmit = async () => {
    if (configuration == null) {
      notification.error({
        message: 'No configuration to update',
        description: `Please load a configuration before submitting to save`,
      });
      return;
    }
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const postResult = await postConfigurations(token.accessToken, [configuration!]);
    if (!postResult.isSuccess) {
      notification.error({
        message: 'Failed to save configuration',
        description: postResult.errors!.join('\n'),
      });

    }
    else {
      notification.success({
        message: 'Configuration saved successfully',
        description: `Number of updated configs: ${postResult.data}`,
      });
    };
  };

  return (
    <div className="container flex-admin-vertical-container" style={{ width: '900px' }}>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Enable Top Bookers">
          <Switch checked={configuration?.shouldEnableTopBookers} onChange={handleToggleChange} />
        </Form.Item>
        <Form.Item label="List of booker Azure Ids, one per line">
          <Input.TextArea
            value={configuration?.topBookers?.split(',')?.join('\n')}
            onChange={updateBookers}
            placeholder="List of Booker Azure Ids, one per line"
            rows={4}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminTopBookers;