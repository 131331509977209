import { useEffect, useState } from 'react';
import { Header } from '../header/header';
import { TeamMemberModel, TeamModel, UserModel } from '../../dto/reporting-models';
import { reportingService } from '../../services/reporting-service';
import { useMsal } from '@azure/msal-react';
import { scopes } from '../../authConfig';
import { GetAccessToken } from '../../utils/auth-utils';
import { Button, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { TeamCard } from './team-card';
import { TeamForm } from './team-form';

export const Ums = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [displayAddButton, shouldDisplayAddButton] = useState<boolean>(true);
  const [isSpinning, setIsSpinning] = useState(false);
  const [teamOptions, setTeamOptions] = useState<TeamModel[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [shoudlDisplayTeamForm, setShouldDisplayTeamForm] = useState<boolean>(false);

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    setIsSpinning(true);

    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const teams = await reportingService.getTeamsAsync(token.accessToken);
    setTeamOptions(teams);
    const users = await reportingService.getUsersAsync(token.accessToken);
    setUsers(users);

    setIsSpinning(false);
  };

  const submitTeam = async (team: TeamModel, members: TeamMemberModel[]) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    let id = team.id;
    if (id === 0) {
      team = await reportingService.postTeamAsync(token.accessToken, team);
    } else {
      await reportingService.updateTeamAsync(token.accessToken, team);
    }
    await reportingService.modifyMembersAsync(
      token.accessToken,
      id,
      members.map((m) => m.id)
    );
    fetchInfo();
  };

  const deleteTeam = async (teamId: number) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    await reportingService.deleteTeamAsync(token.accessToken, teamId);
    setTeamOptions(teamOptions.filter((t) => t.id !== teamId));
  };

  const addTeam = () => {
    const newTeam = {
      id: 0,
      leadId: '',
      name: '',
    } as TeamModel;
    setTeamOptions([...teamOptions, newTeam]);
    setShouldDisplayTeamForm(true);
    shouldDisplayAddButton(false);
  };

  return (
    <>
      <Header />
      <div className="container flex-admin-vertical-container" style={{ width: 900 }}>
        <Row justify="space-between" align="bottom" style={{ marginBottom: '20px' }}>
          <Title level={4} style={{ marginBottom: 0 }}>
            Team Management
          </Title>
          <Button type="primary" disabled={!displayAddButton} onClick={addTeam} icon={<PlusOutlined />}>
            Add Team
          </Button>
        </Row>
        {shoudlDisplayTeamForm && (
          <TeamForm
            teamMembers={[]}
            team={teamOptions.find((t) => t.id === 0)!}
            users={users}
            onSubmit={submitTeam}
            cancel={() => {
              setShouldDisplayTeamForm(false);
              shouldDisplayAddButton(true);
              setTeamOptions(teamOptions.filter((t) => t.id !== 0));
            }}
          />
        )}
        {teamOptions.map((team) => (
          <TeamCard team={team} users={users} shouldDisplayAddButton={shouldDisplayAddButton} onSubmit={submitTeam} deleteTeam={deleteTeam} />
        ))}
      </div>
    </>
  );
};
