import { useMsal } from '@azure/msal-react';
import { useEffect, useRef, useState } from 'react';
import { GetAccessToken } from '../../utils/auth-utils';
import { scopes } from '../../authConfig';
import { salesAppointmentsService } from '../../services/sales-appointments-service';
import { Button, notification, Row, Typography } from 'antd';
import { CommonCard } from '../common/common-card';
import { ScriptEditor } from './script-editor';
import { BookingStatus, ScriptsWithObjections } from '../../dto/model';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const ScriptPage = () => {
  const { instance, inProgress, accounts } = useMsal();
  const loadingBarRef = useRef(null);
  const [scriptsWithObjections, setScriptWithObjections] = useState<ScriptsWithObjections[]>();
  const [activeTabKey, setActiveTabKey] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayScriptForm, setDisplayScriptForm] = useState<boolean>(false);
  const [displayAddButton, setDisplayAddButton] = useState<boolean>(true);

  const loadScripts = async () => {
    try {
      setDisplayScriptForm(false);
      setDisplayAddButton(true);
      setIsLoading(true);
      setScriptWithObjections([]);
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const scriptsData = await salesAppointmentsService.getScripts(await token.accessToken!);
      setScriptWithObjections(scriptsData.data);
    } catch (e: any) {
      notification.open({
        message: e.toString(),
        placement: 'topRight',
        type: 'error',
        duration: 9999,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadScripts();
  }, []);

  const addScriptWithObjections = () => {
    const newScript: ScriptsWithObjections = {
      name: '',
      description: '',
      objections: [],
      scripts: Object.keys(BookingStatus).map((key) => ({
        id: -1,
        name: '',
        bookingStatus: key,
        scriptText: '',
        isDeleted: false,
        lastModifiedBy: '',
        lastModifiedDate: new Date(),
      })),
    };
    setScriptWithObjections([...scriptsWithObjections!, newScript]);
    setDisplayScriptForm(true);
    setDisplayAddButton(false);
  };

  const onSubmit = async (form: ScriptsWithObjections) => {
    try {
      setIsLoading(true);
      if (!form?.name) {
        notification.error({
          message: 'Name is required',
          placement: 'topRight',
          duration: 20,
        });
        setIsLoading(false);
        return;
      }
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      await salesAppointmentsService.postScripts([form], token.accessToken!);
      await loadScripts();
      setIsLoading(false);
    } catch (e: any) {
      notification.open({
        message: e.toString(),
        placement: 'topRight',
        type: 'error',
        duration: 9999,
      });
    }
  };

  const onCancel = () => {
    setDisplayScriptForm(false);
    setDisplayAddButton(true);
  };

  const onDelete = async (form: ScriptsWithObjections) => {
    try {
      setIsLoading(true);
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      form.objections.map((x) => (x.isDeleted = true));
      form.scripts.map((x) => (x.isDeleted = true));
      await salesAppointmentsService.postScripts([form], token.accessToken!);
      await loadScripts();
      setIsLoading(false);
    } catch (e: any) {
      notification.open({
        message: e.toString(),
        placement: 'topRight',
        type: 'error',
        duration: 9999,
      });
    }
  };
  return (
    <div className="container flex-admin-vertical-container" style={{ width: 900 }}>
      <Row justify="space-between" align="bottom" style={{ marginBottom: '20px' }}>
        <Title level={4} style={{ marginBottom: 0 }}>
          Customer Journey Rules
        </Title>
        {displayAddButton && (
          <Button type="primary" onClick={addScriptWithObjections} icon={<PlusOutlined />}>
            Add Script
          </Button>
        )}
      </Row>
      {displayScriptForm && <ScriptEditor model={scriptsWithObjections?.filter((x) => !x.name)[0]!} onSubmit={onSubmit} onCancel={onCancel} />}

      {!displayScriptForm && scriptsWithObjections?.map((script, index) => <CommonCard key={index} EditComponent={ScriptEditor} model={script} onDelete={onDelete} onSubmit={onSubmit} />)}
    </div>
  );
};
