
export const convertToCSV = (objArray: any[]) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

  const convertValue = (value: any): string => {
    if (value === null) {
      return "null";
    }
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }
    return value.toString();
  };

  let str = `${Object.keys(array[0]).map(key => `"${key}"`).join(",")}\r\n`;
  return array.reduce((str: string, next: any) => {
    str += `${Object.values(next).map(value => `"${convertValue(value)}"`).join(",")}\r\n`;
    return str;
  }, str);
};

export const handleDownloadAll = async (data: any[], filename: string) => {
  const csvData = convertToCSV(data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

