import "../../admin-page.css";
import { Button, Statistic } from "antd";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { GetAccessToken } from "../../../../utils/auth-utils";
import { scopes } from "../../../../authConfig";

const AdminTranscription = () => {
  const { instance, inProgress, accounts } = useMsal();
  const sttRootUrl = (process as any).env.REACT_APP_STT_ROOT_URL;
  const [failedBlobs, setFailedBlobs] = useState<number>(0);
  const [sentToWhBlobs, setSentToWhBlobs] = useState<number>(0);

  useEffect(() => {
    getCurrentMetricsAsync();
  });

  const getCurrentMetricsAsync = async () => {
    try {
      const token = await GetAccessToken(
        instance,
        inProgress,
        scopes.transcriptionsApi
      );
      const response = await fetch(`${sttRootUrl}/api/v1/admin/metrics`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token.accessToken}`,
        }),
      });
      const data = await response.json();
      const responseBody: Record<string, string> = data;
      setFailedBlobs(
        parseInt(responseBody["Failed"]) ? parseInt(responseBody["Failed"]) : 0
      );
      setSentToWhBlobs(
        parseInt(responseBody["SentToWH"])
          ? parseInt(responseBody["SentToWH"])
          : 0
      );
    } catch (error) { }
  };

  const restartFailedBlobsAsync = async () => {
    try {
      const token = await GetAccessToken(
        instance,
        inProgress,
        scopes.transcriptionsApi
      );
      const response = await fetch(`${sttRootUrl}/api/v1/admin/restart`, {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${token.accessToken}`,
        }),
      });

      if (response.ok) {
        getCurrentMetricsAsync();
        alert("Restart has been triggered");
      } else {
        alert("Failed to trigger Restart");
      }
    } catch (error) {
      alert("Failed to trigger Restart");
    }
  };

  return (
    <>
      <div className="container flex-admin-vertical-container">
        <h1>Info Metrics</h1>
        <div className="flex-admin-container">
          <Statistic title="Failed blobs" value={failedBlobs} />
          <Statistic title="Sent to WH blobs" value={sentToWhBlobs}></Statistic>
        </div>
      </div>

      <div className="container flex-admin-vertical-container">
        <h1>Actions</h1>
        <div className="flex-admin-container">
          <Button onClick={restartFailedBlobsAsync}>
            Restart Failed blobs
          </Button>
        </div>
      </div>
    </>
  );
};

export default AdminTranscription;
