import { Button, DatePicker, Form, GetProp, Image, Input, message, Select, Switch, TimePicker, Tooltip, Upload, UploadFile, UploadProps } from 'antd';
import { Achievement, ActionDto } from '../../../../dto/reporting-models';
import { useState } from 'react';
import dayjs from 'dayjs';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

type AchievementFormProps = {
  achievement: Achievement;
  actions: ActionDto[];
  onSubmit: (achievement: Achievement) => void;
  cancel: () => void;
};

export const AchievementForm = ({ achievement, actions, onSubmit, cancel }: AchievementFormProps) => {
  const [form] = Form.useForm();
  const [currentAchievement, setCurrentAchievement] = useState<Achievement>(achievement);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>(
    achievement.icon
      ? [
          {
            uid: '-1',
            name: `${achievement.id}.png`,
            status: 'done',
            url: decodeURIComponent(achievement.icon),
          },
        ]
      : []
  );

  const days = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];

  const startTimeInterval = achievement.fromHour ? dayjs(achievement.fromHour, 'HH:mm:ss') : undefined;
  const endTimeInterval = achievement.toHour ? dayjs(achievement.toHour, 'HH:mm:ss') : undefined;
  const dueDate = achievement.dueDate ? dayjs(achievement.dueDate) : undefined;
  const onFinish = (achievement: Achievement) => {
    if (fileList[0] !== null && fileList[0] !== undefined && fileList[0].name !== `${currentAchievement.id}.png`) {
      const reader = new FileReader();
      reader.readAsDataURL(fileList[0].originFileObj as File);
      reader.onload = () => {
        const base64String = reader.result as string;
        currentAchievement.icon = base64String;
        onSubmit(currentAchievement);
        form.resetFields();
      };
      reader.onerror = () => {
        message.error('Failed to read file');
      };
      return;
    }
    onSubmit(currentAchievement);
    form.resetFields();
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    if (newFileList.length > 0 && newFileList[0].type === 'image/png' && (await validateImageDimensions(newFileList[0].originFileObj as RcFile))) {
      setFileList(newFileList);
    } else {
      setFileList([]);
    }
  };

  const validateImageDimensions = (file: RcFile): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width === 100 && img.height === 100) {
          resolve(true); // Allow upload
        } else {
          message.error('Image must be exactly 100x100 pixels!');
          resolve(false); // Reject upload
        }
      };

      img.onerror = () => {
        message.error('Failed to load image.');
        reject(false);
      };
    });
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" style={{ marginBottom: '20px' }}>
      <Form.Item required label="Name" name="name" initialValue={achievement.name}>
        <Input placeholder="Achievement title" onChange={(e) => setCurrentAchievement({ ...currentAchievement, name: e.target.value })} />
      </Form.Item>
      <Form.Item required label="Description" name="description" initialValue={achievement.description}>
        <Input placeholder="Achievement description" onChange={(e) => setCurrentAchievement({ ...currentAchievement, description: e.target.value })} />
      </Form.Item>
      <Form.Item required label="Points" name="points" initialValue={achievement.points}>
        <Input type="number" placeholder="Points" onChange={(e) => setCurrentAchievement({ ...currentAchievement, points: +e.target.value })} />
      </Form.Item>
      <Form.Item required label="Required number of actions" name="target" initialValue={achievement.target}>
        <Input type="number" placeholder="Target" onChange={(e) => setCurrentAchievement({ ...currentAchievement, target: +e.target.value })} />
      </Form.Item>
      <Form.Item required label="Required actions" name="actions" initialValue={achievement.actions}>
        <Tooltip title="Select actions which will be counted towards achievement progress">
          <Select
            value={currentAchievement.actions}
            mode="multiple"
            onChange={(value) => setCurrentAchievement({ ...currentAchievement, actions: value })}
            options={actions.map((action) => {
              return { label: action.actionType, value: action.actionId.toString() };
            })}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item label="Days of the week" name="days" initialValue={achievement.days}>
        <Tooltip title="Specify during which days of the week the achievement is active">
          <Select
            value={currentAchievement.days}
            mode="multiple"
            onChange={(value) => setCurrentAchievement({ ...currentAchievement, days: value })}
            options={days.map((action) => {
              return { label: action.name, value: action.id };
            })}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item label="Time frame" name="time" initialValue={[startTimeInterval, endTimeInterval]}>
        <Tooltip title="Specify time frame when achievement progress is tracked">
          <TimePicker.RangePicker
            defaultValue={[startTimeInterval, endTimeInterval]}
            format="HH:mm:ss"
            minuteStep={10}
            showSecond={false}
            onChange={(value) => setCurrentAchievement({ ...currentAchievement, fromHour: value?.[0]?.format('HH:mm:ss'), toHour: value?.[1]?.format('HH:mm:ss') })}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item label="Deadline" name="deadline" initialValue={dueDate}>
        <Tooltip title="Specify when achievement should become inactive">
          <DatePicker defaultValue={dueDate} onChange={(value) => setCurrentAchievement({ ...currentAchievement, dueDate: value.toISOString() })} />
        </Tooltip>
      </Form.Item>
      <Form.Item label="Accumulative" name="accumulative" valuePropName="checked" initialValue={achievement.isAccumulative}>
        <Tooltip title="If checked, progress will be accumulated over time">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={false}
            value={currentAchievement.isAccumulative}
            onChange={(value) => setCurrentAchievement({ ...currentAchievement, isAccumulative: value })}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item required label="Icon" name="icon">
        <Upload
          beforeUpload={(file) => {
            const isPNG = file.type === 'image/png';
            if (!isPNG) {
              message.error(`${file.name} is not a png file`);
            }
            return false;
          }}
          listType="picture-circle"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        {previewImage && (
          <Image
            wrapperStyle={{ display: 'none' }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(''),
            }}
            src={previewImage}
          />
        )}
      </Form.Item>
      <div className="bg-gray-50 p-4 rounded-md" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button onClick={cancel}>Cancel</Button>
        {fileList.length > 0 && (
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        )}
      </div>
    </Form>
  );
};
