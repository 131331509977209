import { CustomerRule } from "../../../../dto/admin-rule-models"
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Modal,
  Typography,
} from 'antd';
import { CustomerRuleForm } from "./customer-rule-form";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { confirm } = Modal;
const { Title } = Typography;

type CustomerRuleCardProps = {
  rule: CustomerRule
  onSubmit: (rule: CustomerRule) => void
  shouldDisplayAddButton: (status: boolean) => void
}

export const CustomerRuleCard = ({ rule, onSubmit, shouldDisplayAddButton }: CustomerRuleCardProps) => {

  const [shouldEdit, setShouldEdit] = useState(false);
  const onSubmitEdit = (rule: CustomerRule) => {
    onSubmit(rule);
    setShouldEdit(false);
  }

  const setEditDisplay = (status: boolean) => {
    shouldDisplayAddButton(!status);
    setShouldEdit(status);
  }

  const deleteRule = () => {
    const updatedRule = { ...rule, isDeleted: true };
    onSubmit(updatedRule);
  }

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this rule?',
      icon: <DeleteOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteRule();
      },
    });
  }

  return (
    <Card style={{ marginBottom: '25px' }}>
      {shouldEdit ?
        <CustomerRuleForm rule={rule} onSubmit={onSubmitEdit} onCancel={() => setEditDisplay(false)} />
        : <>
          <Title level={3}>{rule.title}</Title>
          <p>{rule.description}</p>

          <Button
            onClick={() => setEditDisplay(true)}
            icon={<EditOutlined />}
            style={{ marginRight: '10px' }}
          >
            Edit
          </Button>
          <Button
            onClick={showDeleteConfirm}
            icon={<DeleteOutlined />}
          >
            Delete
          </Button>
        </>}
    </Card>
  )
}