import { Button, Tabs } from 'antd';
import ReportingPage from './reporting-page';
import { TeamReportingPage } from './team-reporting-page';
import { Header } from '../header/header';
import { useState } from 'react';

const ReportingPageWithTabs = () => {
  const [individualView, setIndividualView] = useState<boolean>(true);
  return (
    <>
      <Header centerComponents={<Button onClick={() => setIndividualView(!individualView)}>Change View</Button>} />
      {individualView ? <ReportingPage hideHeader={true} /> : <TeamReportingPage />}
    </>
  );
};

export default ReportingPageWithTabs;
