import { Button, Card, Col, Modal, Row } from 'antd';
import { Achievement, ActionDto } from '../../../../dto/reporting-models';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { AchievementForm } from './achievement-form';

const { confirm } = Modal;

type AchievementCardParams = {
  achievement: Achievement;
  actionsInfo: ActionDto[];
  onSubmit: (rule: Achievement) => void;
  shouldDisplayAddButton: (status: boolean) => void;
  deleteAchievement: (achievementId: number) => void;
};

export const AchievementCard = ({ achievement, actionsInfo, onSubmit, shouldDisplayAddButton, deleteAchievement }: AchievementCardParams) => {
  const [shouldEdit, setShouldEdit] = useState(false);

  const cancelEdit = () => {
    setShouldEdit(false);
    shouldDisplayAddButton(true);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this achievement?',
      icon: <DeleteOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAchievement(achievement.id);
      },
    });
  };

  return (
    <Card style={{ margin: '3px 0' }}>
      {shouldEdit ? (
        <AchievementForm
          achievement={achievement}
          actions={actionsInfo}
          onSubmit={(achievement: Achievement) => {
            onSubmit(achievement);
            setShouldEdit(false);
          }}
          cancel={cancelEdit}
        />
      ) : (
        <Row>
          <Col span={16}>
            <p>{achievement.name}</p>
            <p>{achievement.description}</p>
            <Button
              onClick={() => {
                setShouldEdit(true);
                shouldDisplayAddButton(false);
              }}
              icon={<EditOutlined />}
              style={{ marginRight: '10px' }}
            >
              Edit
            </Button>
            <Button danger onClick={showDeleteConfirm} icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Col>
          <Col span={8}>{achievement.icon && <img src={decodeURIComponent(achievement.icon)} alt="achievement icon" style={{ float: 'right', width: '100px', height: '100px' }} />}</Col>
        </Row>
      )}
    </Card>
  );
};
