import { useMsal } from '@azure/msal-react';
import { Header } from '../header/header';
import './profile-page.css';
import { Button, Card, Carousel, Empty, Form, Image, Progress, Row, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import RankingCard from '../reporting/ranking-card';
import { strings } from '../../lang';
import { Achievement, ActionDto, RatingDto, TopBooker, UserDataDto } from '../../dto/reporting-models';
import { GetAccessToken } from '../../utils/auth-utils';
import { scopes } from '../../authConfig';
import { useEffect, useState } from 'react';
import { reportingService } from '../../services/reporting-service';
import dayjs from 'dayjs';
import { get } from 'http';

export const ProfilePage = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [form] = Form.useForm();
  const [userProfile, setUserProfile] = useState<UserDataDto | undefined>(undefined);
  const [expRaiting, setExpRaiting] = useState<RatingDto | null>(null);
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [achievementsRarity, setAchievementsRarity] = useState<Record<number, number>>([]);
  const [actions, setActions] = useState<ActionDto[] | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [customizationChanged, setCustomizationChanged] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<string | undefined>(userProfile?.selectedBookingButton ? userProfile?.selectedBookingButton : 'default');

  useEffect(() => {
    (async () => {
      const accessToken = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const expOrder = await reportingService.getTopByExperienceAsync(accessToken.accessToken);
      const rarity = await reportingService.getAchievementsRarity(accessToken.accessToken);
      const achievementsResponse = await reportingService.getAchievementsAsync(accessToken.accessToken);
      setExpRaiting(expOrder);
      setAchievementsRarity(rarity);
      setAchievements(achievementsResponse);
      try {
        const response = await reportingService.getUserDataAsync(false, accessToken.accessToken);
        setUserProfile(response);
        setSelectedButton(response.selectedBookingButton);
      } catch (error) {
        setError('Error fetching user data');
      }
    })();
  }, [instance, inProgress]);

  useEffect(() => {
    const fetchActions = async () => {
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      try {
        const actionsResponse = await reportingService.getActionsAsync(token.accessToken);
        setActions(actionsResponse);
      } catch (error) {}
    };
    fetchActions();
  }, []);

  const calculateReceivedXpPercentage = () => {
    return +((userProfile?.currentXp! / userProfile?.nextLevelXp!) * 100).toFixed(1);
  };

  const days = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];

  const setAchievementRarity = (achievement: Achievement) => {
    const rarity = (achievementsRarity[achievement.id] * 100).toFixed(0);
    if (+rarity <= 10) {
      return <div>Rarity: Ultra rare ({rarity}%)</div>;
    } else if (+rarity <= 30) {
      return <div>Rarity: Rare ({rarity}%)</div>;
    } else {
      return <div>Rarity: Common ({rarity}%)</div>;
    }
  };

  const getAchievementTooltip = (achievement: Achievement) => {
    return (
      <>
        {achievement && achievement?.description && <div>{achievement.description}</div>}
        {achievement?.fromHour && (
          <div>
            Time frame: {achievement.fromHour} - {achievement.toHour}
          </div>
        )}
        {achievement?.days && <div>Days: {achievement?.days?.map((d) => `${days.find((day) => day.id === +d)?.name} `)}</div>}
        {achievement?.dueDate && <div>Due date: {dayjs(achievement?.dueDate!).format('DD.MM.YYYY')}</div>}
        {setAchievementRarity(achievement)}
      </>
    );
  };

  const getButtonOptions = () => {
    let options = [{ label: 'Default', value: 'default', className: 'btn-grad-prime' }];

    if (userProfile?.currentLevel && userProfile?.currentLevel >= 4) {
      options.push({ label: 'Bronze Button', value: 'BronzeButton', className: 'btn-grad-bronze' });
    }
    if (userProfile?.currentLevel && userProfile?.currentLevel >= 6) {
      options.push({ label: 'Silver Button', value: 'SilverButton', className: 'btn-grad-silver' });
    }
    if (userProfile?.currentLevel && userProfile?.currentLevel >= 8) {
      options.push({ label: 'Golden Button', value: 'GoldButton', className: 'btn-grad-gold' });
    }

    return options;
  };

  const saveCustomization = async () => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    console.log(selectedButton);
    await reportingService.ModifyCustomizableUIAsync(token.accessToken, { selectedBookingButton: selectedButton });
  };

  const getContent = () => {
    if (error) {
      return (
        <>
          <div className="center-content">
            <Empty description="">
              <h3>{strings.missingUserProfile}</h3>
            </Empty>
          </div>
        </>
      );
    }

    return (
      <div className="profile-page">
        <h2 className="profile-title">{userProfile?.fullName}</h2>
        <div className="profile-info">
          <Row gutter={8}>
            <div style={{ marginRight: 20, width: 800 }}>
              <Card className="activity-card" style={{ maxHeight: 'fit-content', margin: '0 0 20px 0' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3 style={{ margin: '0 0 20px 0' }}>
                    Level {userProfile?.currentLevel} {userProfile?.levelName}
                  </h3>

                  <div className="progress-part">
                    <Tooltip title={`${strings.profileRemainingXpTillNextLvl} ${userProfile?.nextLevelXp! - userProfile?.currentXp!}`}>
                      <Progress strokeColor="black" percent={calculateReceivedXpPercentage()} percentPosition={{ align: 'center', type: 'inner' }} />
                    </Tooltip>
                    <Tooltip
                      overlayStyle={{ whiteSpace: 'pre-line' }}
                      title={actions
                        ?.filter((a) => a.points > 0)
                        .map((action) => (
                          <div>
                            {strings.getActionName(
                              action.actionType as 'Booked' | 'NotReached' | 'ReachedNotBooked' | 'CustomerLost' | 'RevisionQueueAssignment' | 'WrongPhoneNumber' | 'AppointmentHappened'
                            )}{' '}
                            - {action.points}
                          </div>
                        ))}
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 10, fontSize: 34 }} />
                    </Tooltip>
                  </div>
                </div>
              </Card>
              <Card className="activity-card">
                <h3>Achievements</h3>
                <Carousel arrows infinite={false} slidesToShow={4} dots={true} effect="scrollx">
                  {userProfile?.achievements?.map((achievement) => (
                    <Tooltip key={achievement.id} title={getAchievementTooltip(achievements.find((ach) => ach.id === achievement.id)!)}>
                      <div style={{ opacity: achievement.isCompleted ? 1 : 0.5, textAlign: 'center' }}>
                        {achievements.find((ach) => ach.id === achievement.id)?.icon && (
                          <img
                            src={achievements.find((ach) => ach.id === achievement.id)?.icon === null ? '' : decodeURIComponent(achievements.find((ach) => ach.id === achievement.id)?.icon!)}
                            alt="achievement"
                            style={{ display: 'block', margin: '0 auto', width: '100px', height: '100px' }}
                          />
                        )}
                        <h4>
                          {achievement.isCompleted
                            ? achievement.name
                            : `${achievement.name} (${((achievement.progress / achievements.find((ach) => ach.id === achievement.id)?.target!) * 100).toFixed(0)}%)`}
                        </h4>
                      </div>
                    </Tooltip>
                  ))}
                </Carousel>
              </Card>
              {userProfile?.currentLevel && userProfile?.currentLevel >= 4 && (
                <Card className="activity-card">
                  <h3>Customization</h3>
                  <Form
                    wrapperCol={{ span: 7 }}
                    form={form}
                    onFinish={async () => {
                      await saveCustomization();
                      setCustomizationChanged(false);
                    }}
                  >
                    <Form.Item label="Calendar Button">
                      <Select
                        value={getButtonOptions().find((button) => button.value === (selectedButton ?? 'default'))?.value}
                        onChange={(value) => {
                          console.log(value);
                          setCustomizationChanged(true);
                          setSelectedButton(value);
                        }}
                      >
                        {getButtonOptions().map((button) => (
                          <Select.Option style={{ marginTop: '5px' }} key={button.value} value={button.value} className={button.className}>
                            {button.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {customizationChanged && (
                      <div className="bg-gray-50 p-4 rounded-md" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                        <Button
                          onClick={() => {
                            setCustomizationChanged(false);
                            setSelectedButton(userProfile?.selectedBookingButton);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                          Save
                        </Button>
                      </div>
                    )}
                  </Form>
                </Card>
              )}
            </div>
          </Row>
          <Row style={{ height: 'fit-content' }}>
            <RankingCard title={strings.profileTopExperience} rankData={expRaiting ?? null} />
          </Row>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      {getContent()}
    </>
  );
};
