import { AccountInfo, AuthenticationResult, BrowserAuthError, InteractionRequiredAuthError, InteractionStatus, IPublicClientApplication } from '@azure/msal-browser';

let isInitialised = false;

export const GetAccessToken = async (instance: IPublicClientApplication, inProgress: InteractionStatus, scopes: string[]): Promise<AuthenticationResult> => {
  const accessTokenRequest = { scopes };

  if (!isInitialised) {
    await instance.initialize();
    isInitialised = true;
  }

  if (inProgress === InteractionStatus.None) {
    try {
      return await instance.acquireTokenSilent(accessTokenRequest);
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError || e instanceof BrowserAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest);
      }
      //we need this console log to help debug the error on prod
      console.log(e);
      if (e instanceof InteractionRequiredAuthError || e instanceof BrowserAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest);
      }
      throw e;
    }
  }
  throw new Error('Access token could not be acquired');
};

export enum Role {
  BookingManager = 'BookingManager',
  BookingAdmin = 'BookingAdministrator',
  Recorded = 'Recorded',
  CrmSalesRole = 'CrmBookingAgent',
  InboundAgent = 'InboundAgent',
  FieldSalesBookingAgent = 'FieldSalesBookingAgent',
  RevisionAgent = 'RevisionAgent',
  LiveReporter = 'LiveReporter',
  TeamLead = 'TeamLead',
}

export const HasRole = (accounts: AccountInfo[], role: Role) => {
  if (!accounts || accounts.length < 1) {
    return false;
  }

  const userRoles = accounts[0].idTokenClaims?.roles;
  if (!userRoles) {
    return false;
  }

  return userRoles.find((r) => r.toLowerCase() === role.toLowerCase()) !== undefined;
};
