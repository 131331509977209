import { useEffect, useState } from 'react';
import { TeamMemberModel, TeamModel, UserModel } from '../../dto/reporting-models';
import { Button, Card, Col, Modal, Row } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { GetAccessToken } from '../../utils/auth-utils';
import { reportingService } from '../../services/reporting-service';
import { scopes } from '../../authConfig';
import { TeamForm } from './team-form';

const { confirm } = Modal;

interface TeamCardProps {
  team: TeamModel;
  users: UserModel[];
  shouldDisplayAddButton: (status: boolean) => void;
  onSubmit: (team: TeamModel, members: TeamMemberModel[]) => void;
  deleteTeam: (teamId: number) => void;
}

export const TeamCard = ({ team, users, shouldDisplayAddButton, onSubmit, deleteTeam }: TeamCardProps) => {
  const [shouldEdit, setShouldEdit] = useState(false);
  const { instance, inProgress, accounts } = useMsal();
  const [members, setMembers] = useState<TeamMemberModel[]>([]);

  useEffect(() => {
    fetchAgents(team.id);
  }, []);

  const cancelEdit = () => {
    setShouldEdit(false);
    shouldDisplayAddButton(true);
  };

  const fetchAgents = async (teamId: number) => {
    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    const agents = await reportingService.getAgentsByTeamIdAsync(teamId, token.accessToken);
    setMembers(agents);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this team?',
      icon: <DeleteOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTeam(team.id);
      },
    });
  };

  return (
    <Card style={{ margin: '3px 0' }}>
      {shouldEdit ? (
        <TeamForm
          team={team}
          users={users}
          teamMembers={members}
          onSubmit={(team: TeamModel, members: TeamMemberModel[]) => {
            onSubmit(team, members);
            setShouldEdit(false);
          }}
          cancel={cancelEdit}
        />
      ) : (
        <Row>
          <Col span={16}>
            <p>{team.name}</p>
            <Button
              onClick={() => {
                setShouldEdit(true);
                shouldDisplayAddButton(false);
              }}
              icon={<EditOutlined />}
              style={{ marginRight: '10px' }}
            >
              Edit
            </Button>
            <Button danger onClick={showDeleteConfirm} icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  );
};
